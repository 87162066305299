import * as ActionTypes from '../../actions/types/EventActionTypes';

const initialState = {
  busy: false,
  byId: {},
  orderedIds: [],
  migratingWeddingAccount: false,
};

const migrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_MIGRATION_EVENTS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_MIGRATION_EVENTS: {
      // console.log(action.payload);
      const { events } = action.payload;
      const { orderedIds, ...byId } = events.reduce(
        (result, event) => {
          result.orderedIds.push(event.id);
          result[event.id] = event; // eslint-disable-line
          return result;
        },
        { orderedIds: [] }
      );
      // console.log(orderedIds, byId);
      return { ...state, busy: false, byId, orderedIds };
    }
    case ActionTypes.BEGIN_MIGRATING: {
      return { ...state, migratingWeddingAccount: true };
    }
    case ActionTypes.FINISH_MIGRATING: {
      return { ...state, migratingWeddingAccount: false };
    }
    default:
      return state;
  }
};

export default migrationReducer;
