export const REQUEST_CHARTS = 'zola/seatingChart/request_charts';
export const RECEIVE_CHARTS = 'zola/seatingChart/receive_charts';
export const REQUEST_CHART = 'zola/seatingChart/request_chart';
export const RECEIVE_CHART = 'zola/seatingChart/receive_chart';
export const CREATE_CHART = 'zola/seatingChart/create_chart';
export const UPDATE_CHART = 'zola/seatingChart/update_chart';
export const DELETE_CHART = 'zola/seatingChart/delete_chart';
export const REDIRECT_CHART = 'zola/seatingChart/redirect_chart';
export const CREATE_TABLE = 'zola/seatingChart/create_table';
export const DELETE_TABLE = 'zola/seatingChart/delete_table';
export const REORDER_TABLES = 'zola/seatingChart/reorder_tables';
export const SELECT_GUESTS = 'zola/seatingChart/select_guests';
export const CLEAR_SELECTED_GUESTS = 'zola/seatingChart/clear_selected_guests';
export const UNSEAT_SELECTED_GUESTS = 'zola/seatingChart/unseat_selected_guests';
export const EXPAND_GUEST_GROUP = 'zola/seatingChart/expand_guest_group';
export const REORDER_SEATS = 'zola/seatingChart/reorder_seats';
export const REORDER_SEATS_IN_TABLE = 'zola/seatingChart/reorder_seats_in_table';
export const PUSH_SEATS_TO_TABLE = 'zola/seatingChart/push_seats_to_table';
export const ADD_PEOPLE_TO_TABLE = 'zola/seatingChart/add_people_to_table';
export const ADD_PERSON_TO_SEAT = 'zola/seatingChart/add_person_to_seat';
export const CLEAR_SEAT = 'zola/seatingChart/clear_seat';
export const SEARCH_GUEST_LIST = 'zola/seatingChart/search_guest_list';
export const INC_ONBOARDING_STEP = 'zola/seatingChart/inc_onboarding_step';
export const DEC_ONBOARDING_STEP = 'zola/seatingChart/dec_onboarding_step';
export const TOGGLE_FACET_VALUE = 'zola/seatingChart/toggle_facet_value';
export const RESET_FACET_VALUES = 'zola/seatingChart/reset_facet_value';
export const TRIGGER_SEATING_ONBOARDING = 'zola/seatingChart/trigger_seating_onboarding';
export const FINISH_ONBOARDING = 'zola/seatingChart/finish_seating_onboarding';
export const ONBOARDING_SELECTION_STEP = 'zola/seatingChart/onboarding_selection_step';
export const ONBOARDING_TABLE_STEP = 'zola/seatingChart/onboarding_table_step';
export const SET_NEW_CHART_EVENT = 'zola/seatingChart/set_new_chart_event';
export const TOGGLE_FILTER = 'zola/seatingChart/toggle_filter';
export const UPDATE_GUEST_LIST = 'zola/seatingChart/update_guest_list';
export const LOAD_GUEST_LIST = 'zola/seatingChart/load_guest_list';
export const LOAD_FACET_COUNTS = 'zola/seatingChart/load_facet_counts';
export const DRAG_STARTED = 'zola/seatingChart/drag_started';
export const DRAG_TABLE_STARTED = 'zola/seatingChart/drag_table_started';
export const DRAG_ENDED = 'zola/seatingChart/drag_ended';
export const SET_ACTIVE_SEAT_INPUT = 'zola/seatingChart/set_active_seat_input';
export const SHOW_BANNER = 'zola/seatingChart/show_banner';
export const HIDE_BANNER = 'zola/seatingChart/hide_banner';
export const HIDE_SPINNER = 'zola/seatingChart/hide_spinner';
