// TODO: separate search match logic and facet match logic into separate functions
export const isGuestVisible = (guest, activeFacetElements, searchToken) => {
  const matchesSearch =
    searchToken === ''
      ? true
      : guest.display_name != null && guest.display_name.toLowerCase().indexOf(searchToken) > -1;
  // short circuit: return if no search string match -or- no buckets are active
  if (activeFacetElements.length === 0 || !matchesSearch) return matchesSearch;

  // FACET MATCHING LOGIC (seated & attending rsvp buckets):
  // flags:
  const seatedChecked = activeFacetElements.indexOf('seated') > -1;
  const notSeatedChecked = activeFacetElements.indexOf('notSeated') > -1;
  const attendingChecked = activeFacetElements.indexOf('attending') > -1;
  const declinedChecked = activeFacetElements.indexOf('declined') > -1;
  const undecidedChecked = activeFacetElements.indexOf('undecided') > -1;

  const seatedBucketOn = seatedChecked || notSeatedChecked;
  const rsvpBucketOn = attendingChecked || undecidedChecked || declinedChecked;

  // return 'visible' status for couple roles:
  if (guest.couple_role !== null) {
    const caughtBySeatedBucket = (seatedBucketOn && seatedChecked) || !seatedBucketOn;
    const caughtByRsvpBucket = (rsvpBucketOn && attendingChecked) || !rsvpBucketOn;
    return matchesSearch && caughtBySeatedBucket && caughtByRsvpBucket;
  }

  const caughtByNotSeated = guest.table_uuid === null && notSeatedChecked;
  const caughtBySeated = guest.table_uuid !== null && seatedChecked;
  const caughtByAttending = guest.rsvp_type === 'ATTENDING' && attendingChecked;
  const caughtByDeclined = guest.rsvp_type === 'DECLINED' && declinedChecked;
  const caughtByNoResponse = guest.rsvp_type === 'NO_RESPONSE' && undecidedChecked;

  return (
    matchesSearch &&
    (!seatedBucketOn || caughtBySeated || caughtByNotSeated) &&
    (!rsvpBucketOn || caughtByAttending || caughtByDeclined || caughtByNoResponse)
  );
};

// TODO: remove invited/notInvited facets
export const FACETS_SHAPE = {
  seated: {
    key: 'seated',
    label: 'By Seated Status',
    elements: ['seated', 'notSeated'],
  },
  rsvp: {
    key: 'rsvp',
    label: 'By RSVP Status',
    elements: ['attending', 'undecided', 'declined'],
  },
};

export const FACET_ELEMENTS = {
  seated: { key: 'seated', name: 'Seated', bucket: 'seated' },
  notSeated: { key: 'notSeated', name: 'Not Seated', bucket: 'seated' },
  attending: { key: 'attending', name: 'Attending', bucket: 'rsvp' },
  undecided: { key: 'undecided', name: 'No Response', bucket: 'rsvp' },
  declined: { key: 'declined', name: 'Not Attending', bucket: 'rsvp' },
};

export const FACET_MATCHES_SHAPE = {
  seated: [],
  notSeated: [],
  attending: [],
  undecided: [],
  declined: [],
};

export const makeGuestsByID = (guestList) =>
  Object.keys(guestList)
    .reduce((acc, name) => acc.concat(guestList[name]), [])
    .reduce((acc, group) => {
      const newGuests = group.guests.map((guest) => ({
        ...guest,
        affiliation: group.guest_affiliation,
        couple_role: null,
        id: guest.guest_view.id,
      }));
      return acc.concat(newGuests);
    }, [])
    .reduce((acc, guest) => ({ ...acc, [guest.guest_view.id]: guest }), {});

export const makeCoupleObject = (coupleObj) => ({
  primary: {
    first_name: coupleObj.primary_first_name,
    last_name: coupleObj.primary_last_name,
    display_name: `${coupleObj.primary_first_name} ${coupleObj.primary_last_name}`,
    seat_uuid: coupleObj.primary_seat_uuid,
    table_uuid: coupleObj.primary_table_uuid,
    couple_role: 'OWNER',
    isVisible: true,
  },
  partner: {
    first_name: coupleObj.partner_first_name,
    last_name: coupleObj.partner_last_name,
    display_name: `${coupleObj.partner_first_name} ${coupleObj.partner_last_name}`,
    seat_uuid: coupleObj.partner_seat_uuid,
    table_uuid: coupleObj.partner_table_uuid,
    couple_role: 'PARTNER',
    isVisible: true,
  },
});
