import type { WCmsPageSummaryView, WCmsPageView } from '@zola/svc-web-api-ts-client';

export const REQUEST_PAGES = 'zola/website/page/REQUEST_PAGES';
export const RECEIVE_PAGES = 'zola/website/page/RECEIVE_PAGES';
export const TOGGLE_PAGE = 'zola/website/page/TOGGLE_PAGE';
export const MOVE_PAGE = 'zola/website/page/MOVE_PAGE';
export const REQUEST_PAGE = 'zola/website/page/REQUEST_PAGE';
export const RECEIVE_PAGE = 'zola/website/page/RECEIVE_PAGE';
export const ACTIVATE_PAGE = 'zola/website/page/ACTIVATE_PAGE';
export const RECEIVE_HIDDEN_PAGE_SUGGESTIONS = 'zola/website/page/RECEIVE_HIDDEN_PAGE_SUGGESTIONS';
export const REQUEST_PROFILE_IMAGE = 'zola/website/page/REQUEST_PROFILE_IMAGE';
export const RECEIVE_PROFILE_IMAGE = 'zola/website/page/RECEIVE_PROFILE_IMAGE';

export type ReqestPagesAction = {
  type: typeof REQUEST_PAGES;
};

export type ReceivePagesAction = {
  type: typeof RECEIVE_PAGES;
  payload: {
    pages: WCmsPageView[];
  };
};

export type ReceiveHiddenPageSuggestionsAction = {
  type: typeof RECEIVE_HIDDEN_PAGE_SUGGESTIONS;
  payload: {
    hiddenSuggestions: WCmsPageSummaryView[];
  };
};

export type ReceivePageAction = {
  type: typeof RECEIVE_PAGE;
  payload: {
    page: WCmsPageView;
  };
};

export type ActivatePageAction = { type: typeof ACTIVATE_PAGE; activePage: string };

export type MovePageAction = {
  type: typeof MOVE_PAGE;
  oldIndex: number;
  newIndex: number;
};

export type TogglePageAction = {
  type: typeof TOGGLE_PAGE;
  payload: { id: number };
};

export type RequestProfileImageAction = {
  type: typeof REQUEST_PROFILE_IMAGE;
};

export type ReceiveProfileImageAction = {
  type: typeof RECEIVE_PROFILE_IMAGE;
  payload?: string;
};

export type PageActions =
  | ReqestPagesAction
  | ReceivePagesAction
  | ReceivePageAction
  | ReceiveHiddenPageSuggestionsAction
  | TogglePageAction
  | MovePageAction
  | ActivatePageAction
  | ReceiveProfileImageAction;
