import type { WGuestCollectionDetailView, WUploadedImageView } from '@zola/svc-web-api-ts-client';

import * as GuestCollectionActions from '~/actions/types/GuestCollectionActionTypes';
import type { GuestCollectionActionTypes } from '~/actions/types/GuestCollectionActionTypes';
import * as FileUploadActions from '~/actions/types/FileUploadActionTypes';
import type { FileUploadActionTypes } from '~/actions/types/FileUploadActionTypes';

export type GuestCollectionReducerStateType = {
  busy: boolean;
  data: WGuestCollectionDetailView;
  tempImg: WUploadedImageView | null;
  isCreation: boolean;
};

export const initialState: GuestCollectionReducerStateType = {
  busy: false,
  data: {
    image_uuid: '',
    image_link: '',
    link_title: '',
    show_wedding_date: false,
  },
  tempImg: null,
  isCreation: false,
};

const guestCollection = (
  state = initialState,
  action: GuestCollectionActionTypes | FileUploadActionTypes
): GuestCollectionReducerStateType => {
  switch (action.type) {
    case GuestCollectionActions.REQUEST_GUEST_COLLECTION: {
      return {
        ...state,
        busy: true,
      };
    }
    case GuestCollectionActions.RECEIVE_GUEST_COLLECTION: {
      return {
        ...state,
        data: action.payload,
        busy: false,
        isCreation: false,
      };
    }
    case GuestCollectionActions.RECEIVE_NO_GUEST_COLLECTION: {
      return {
        ...state,
        isCreation: true,
      };
    }
    case FileUploadActions.FILE_UPLOADED: {
      return {
        ...state,
        tempImg: action.payload.fileData,
      };
    }
    case GuestCollectionActions.CLEAR_GUEST_COLLECTION_TEMP_IMG: {
      return {
        ...state,
        tempImg: null,
      };
    }
    case GuestCollectionActions.CLEAR_GUEST_COLLECTION_SAVED_IMG: {
      return {
        ...state,
        data: {
          ...state.data,
          image_link: undefined,
        },
      };
    }

    default:
      return state;
  }
};

export default guestCollection;
