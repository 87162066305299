import type { WCardSettingView } from '@zola/svc-web-api-ts-client';

const DEFAULT_QUANTITY = 100;

type QuantityOption = {
  label: string;
  value: number;
};

export const getCustomizationDefaultQuantity = (
  cardSettings: WCardSettingView | undefined
): number => {
  return cardSettings?.qty_default || DEFAULT_QUANTITY;
};

export const getCustomizationQuantityOptions = (
  cardSettings: WCardSettingView | undefined
): Array<QuantityOption> => {
  const {
    qty_min: minQty = 100,
    qty_max: maxQty = 400,
    qty_increment: increment = 10,
    qty_single_allowed: singleAllowed = false,
    qty_unit_multiplier: unitMultiplier = 1,
  } = cardSettings || {};

  const quantityOptions = [];

  // If proofs are allowed, add the single option
  if (singleAllowed && minQty > 1) {
    quantityOptions.unshift({ label: `${unitMultiplier} (Proof)`, value: 1 });
  }

  // Then add items from min to max, incrementing by the specified amount.
  for (let i = minQty; i <= maxQty; i += increment) {
    quantityOptions.push({ label: `${i * unitMultiplier}`, value: i });
  }

  return quantityOptions;
};
