import type {
  UpdateWeddingRequest,
  WWeddingAccountView,
  WWeddingThemeV2View,
  WWeddingView,
} from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../actions/types/WeddingActionTypes';
import type { WeddingActionTypes } from '../actions/types/WeddingActionTypes';

export type WeddingReducerStateType = {
  busy: boolean;
  wedding: UpdateWeddingRequest & WWeddingView;
  account: WWeddingAccountView;
  theme: WWeddingThemeV2View | null;
  themeGroupTitle: string;
};

const initialState: WeddingReducerStateType = {
  busy: false,
  wedding: {},
  account: {},
  theme: null,
  themeGroupTitle: '',
};

const weddingReducer = (
  state = initialState,
  action: WeddingActionTypes
): WeddingReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_WEDDING: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_WEDDING: {
      return Object.assign({}, state, {
        wedding: action.payload.wedding,
        busy: false,
      });
    }
    case ActionTypes.RECEIVE_WEDDING_ACCOUNT: {
      return Object.assign({}, state, { account: action.payload.account, busy: false });
    }
    case ActionTypes.THEME_REQUESTED: {
      return state;
    }
    case ActionTypes.THEMEGROUP_REQUESTED: {
      return state;
    }
    case ActionTypes.THEMEGROUP_RECEIVED: {
      return Object.assign({}, state, { themeGroupTitle: action.payload.themeGroup.title });
    }
    case ActionTypes.THEME_RECEIVED: {
      return Object.assign({}, state, { theme: action.payload.theme });
    }
    case ActionTypes.DISABLE_WEBSITE_ONBOARDING: {
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, { disable_website_onboarding: true }),
      });
    }
    case ActionTypes.ENABLE_GUEST_LIST_ONBOARDING: {
      const account = { ...state.account, disable_guest_list_onboarding: false };
      return Object.assign({}, state, { account });
    }
    default:
      return state;
  }
};

export default weddingReducer;
