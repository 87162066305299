import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import type {
  WRegistryCollectionItemView,
  WRegistryCollectionSearchView,
} from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../actions/types/RegistryCollectionActionTypes';

export type RegistryCollectionStateType = {
  initialized: boolean;
  busy: boolean;
  /** list of all facets */
  facets: WRegistryCollectionSearchView['facets'];
  defaultCollectionObjectId: WRegistryCollectionSearchView['default_collection_object_id'];
  defaultCollection: WRegistryCollectionSearchView['default_collection'];
  otherCollections: WRegistryCollectionSearchView['other_collections'];
  collectionList: WRegistryCollectionSearchView['collection_list'];
  isFlattenedView: boolean;
  /** object with key: WAwsFacet.key (ex. 'PRICE'), value: array of WAwsBucket.options.value (ex. ['ONE_HUNDRED_150', 'GROUP_GIFT']) */
  selectedFacetValues: {
    [facetKey: string]: string[];
  };
  sort: string;
  selectedCollectionObject?: { value: string; label: string } | null;
  collectionItem: unknown;
  isPDPDefaultGiftCard: boolean;
  defaultGiftCard?: WRegistryCollectionItemView;
};

const initialState: RegistryCollectionStateType = {
  // byId: {},
  // orderedIds: [],
  busy: false,
  initialized: false,
  defaultCollectionObjectId: undefined,
  defaultCollection: [],
  otherCollections: [],
  facets: [],
  selectedFacetValues: {},
  sort: '',
  isFlattenedView: false,
  selectedCollectionObject: null,
  collectionList: [],
  collectionItem: null,
  isPDPDefaultGiftCard: false,
};

const registryCollectionsReducer = (
  state = initialState,
  action: ActionTypes.RegistryCollectionActionTypes
): RegistryCollectionStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_SEARCH: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_SEARCH: {
      // The endpoint is annoying so this is necessary
      const collectionList = state.initialized
        ? state.collectionList
        : action.payload.collection_list;

      return Object.assign({}, state, {
        busy: false,
        initialized: true,
        facets: action.payload.facets,
        defaultCollectionObjectId: action.payload.default_collection_object_id,
        defaultCollection: action.payload.default_collection,
        otherCollections: action.payload.other_collections,
        collectionList,
        isFlattenedView: false,
      });
    }
    // Adds facet value to existing facets values for given facet key
    case ActionTypes.TOGGLE_FACET_VALUE: {
      // existing facet values for given key
      const facetValues = state.selectedFacetValues[action.payload.facetKey]
        ? [...state.selectedFacetValues[action.payload.facetKey]]
        : [];
      // if already applied
      const index = _findIndex(facetValues, (i) => i === action.payload.valueKey);
      // remove
      if (index > -1 || action.payload.valueKey === '') {
        facetValues.splice(index, 1);
        // else not applied, insert at end
      } else {
        facetValues.push(action.payload.valueKey);
      }
      const selectedFacetValues = { ...state.selectedFacetValues };
      if (_isEmpty(facetValues)) {
        delete selectedFacetValues[action.payload.facetKey];
      } else {
        selectedFacetValues[action.payload.facetKey] = facetValues;
      }

      return Object.assign({}, state, { selectedFacetValues });
    }
    // replace facet key with array of passed facet values
    case ActionTypes.REPLACE_FACET_VALUES: {
      const selectedFacets = Object.assign({}, state.selectedFacetValues);
      if (_isEmpty(action.payload.facetValues)) {
        delete selectedFacets[action.payload.facetKey];
      } else {
        selectedFacets[action.payload.facetKey] = action.payload.facetValues;
      }
      return Object.assign({}, state, {
        selectedFacetValues: selectedFacets,
      });
    }
    case ActionTypes.RESET_FACET_VALUES: {
      if (action.payload.facetKey) {
        const selectedFacets = Object.assign({}, state.selectedFacetValues);

        return Object.assign({}, state, {
          selectedFacetValues: _omit(selectedFacets, action.payload.facetKey),
        });
      }

      return Object.assign({}, state, { selectedFacetValues: {} });
    }
    case ActionTypes.UPDATE_SORT: {
      return Object.assign({}, state, { sort: action.payload.sort });
    }
    case ActionTypes.SELECT_COLLECTION: {
      return Object.assign({}, state, {
        selectedCollectionObject: action.payload.collectionObject,
      });
    }
    case ActionTypes.SELECT_COLLECTION_BY_ID: {
      const collectionObject =
        state.collectionList?.[
          _findIndex(state.collectionList, (c) => c.object_id === action.payload.collectionObjectId)
        ];
      return Object.assign({}, state, {
        selectedCollectionObject: {
          label: collectionObject?.title,
          value: collectionObject?.object_id,
        },
      });
    }
    case ActionTypes.REQUEST_DEFAULT_GIFT_CARD:
    case ActionTypes.REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID: {
      return Object.assign({}, state);
    }
    case ActionTypes.RECEIVE_DEFAULT_GIFT_CARD: {
      return Object.assign({}, state, {
        defaultGiftCard: action.payload,
        isPDPDefaultGiftCard: true,
      });
    }
    case ActionTypes.RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID: {
      return Object.assign({}, state, { collectionItem: action.payload });
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default registryCollectionsReducer;
