import * as ActionTypes from '../actions/types/RegistryActionTypes';

const initialState = {
  busy: false,
  public: false,
  status: null,
  is_public: false,
  has_shipping_address: false,
};

const registryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_REGISTRY: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_REGISTRY: {
      return Object.assign({}, state, {
        ...action.payload,
        busy: false,
        is_public: action.payload.public,
      });
    }
    case ActionTypes.REGISTRY_PUBLISHED: {
      return Object.assign({}, state, { is_public: !state.is_public, public: !state.public });
    }
    case ActionTypes.HAS_SHIPPING_ADDRESS: {
      return Object.assign({}, state, { has_shipping_address: true });
    }
    case ActionTypes.REGISTRY_ERROR: {
      return Object.assign({}, state, { busy: false });
    }
    default:
      return state;
  }
};

export default registryReducer;
