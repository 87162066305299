export const CustomizationActionTypes = {
  CUSTOM_NOTES_FETCH_FULFILLED: 'zola/cards/project/customization/CUSTOM_NOTES_FETCH_FULFILLED',
  CUSTOM_NOTES_FETCH_REJECTED: 'zola/cards/project/customization/CUSTOM_NOTES_FETCH_REJECTED',
  REVIEW_SUMMARY_FETCH_FULFILLED: 'zola/cards/project/customization/REVIEW_SUMMARY_FETCH_FULFILLED',
  SET_ACTIVE_CUSTOMIZATION_FIELD: 'zola/cards/project/customization/SET_ACTIVE_CUSTOMIZATION_FIELD',
  SET_ACTIVE_GUEST_GROUP_ID: 'zola/cards/project/customization/SET_ACTIVE_GUEST_GROUP_ID',
  SET_PENDING_CHANGES: 'zola/cards/project/customization/SET_PENDING_CHANGES',
  SET_ACTIVE_MOBILE_TOOL_PANEL: 'zola/cards/project/customization/SET_ACTIVE_MOBILE_TOOL_PANEL',
};

export default CustomizationActionTypes;
