import type {
  GetChecklistRequestV2,
  WChecklistTaskView,
  WChecklistTemplateView,
  WChecklistView,
  WChecklistViewV2,
  WGlobalStatView,
} from '@zola/svc-web-api-ts-client';

export const REQUEST_CHECKLIST = 'zola/checklist/REQUEST_CHECKLIST';
export const UPDATE_CHECKLIST = 'zola/checklist/UPDATE_CHECKLIST';
export const RECEIVE_CHECKLIST = 'zola/checklist/RECEIVE_CHECKLIST';
export const RECEIVE_CHECKLIST_V2 = 'zola/checklist/RECEIVE_CHECKLIST_V2';
export const ADD_TASK = 'zola/checklist/ADD_TASK';
export const UPDATE_TASK = 'zola/checklist/UPDATE_TASK';
export const REMOVE_TASK = 'zola/checklist/REMOVE_TASK';
export const RECEIVE_TASK = 'zola/checklist/RECEIVE_TASK';
export const REQUEST_TEMPLATES = 'zola/checklist/REQUEST_TEMPLATES';
export const RECEIVE_TEMPLATES = 'zola/checklist/RECEIVE_TEMPLATES';
export const SELECT_TASK = 'zola/checklist/SELECT_TASK';
export const UNSELECT_TASK = 'zola/checklist/UNSELECT_TASK';
export const TOGGLE_COMPLETED = 'zola/checklist/TOGGLE_COMPLETED';
export const INC_ONBOARD_STEP = 'zola/checklist/INC_ONBOARD_STEP';
export const DEC_ONBOARD_STEP = 'zola/checklist/DEC_ONBOARD_STEP';
export const TOGGLE_ONBOARD = 'zola/checklist/TOGGLE_ONBOARD';
export const REQUEST_STATS = 'zola/checklist/REQUEST_STATS';
export const RECEIVE_STATS = 'zola/checklist/RECEIVE_STATS';
export const RESET_CHECKLIST = 'zola/checklist/RESET_CHECKLIST';
export const REQUEST_TRADITIONS = 'zola/checklist/REQUEST_TRADITIONS';
export const RECEIVE_TRADITIONS = 'zola/checklist/RECEIVE_TRADITIONS';
export const TOGGLE_TEMPLATES = 'zola/checklist/TOGGLE_TEMPLATES';
export const TOGGLE_CHECKLIST_FILTER = 'zola/checklist/TOGGLE_CHECKLIST_FILTER';
export const REPLACE_CHECKLIST_FILTERS = 'zola/checklist/REPLACE_CHECKLIST_FILTER';
export const CLEAR_CHECKLIST_FILTER = 'zola/checklist/CLEAR_CHECKLIST_FILTER';
export const RECEIVE_CHECKLIST_FILTERED = 'zola/checklist/RECEIVE_CHECKLIST_FILTERED';
export const RECEIVE_CHECKLIST_FILTERED_GROUPS = 'zola/checklist/RECEIVE_CHECKLIST_FILTERED_GROUPS';
export const TOGGLE_INVITE_PARTNER_VIEW = 'zola/checklist/TOGGLE_INVITE_PARTNER_VIEW';

type RequestChecklistActionType = {
  type: typeof REQUEST_CHECKLIST;
};

type ReceiveChecklistActionType = {
  type: typeof RECEIVE_CHECKLIST;
  payload: { checklist: WChecklistView };
};

type RequestStatsActionType = {
  type: typeof REQUEST_STATS;
};

type ReceiveStatsActionType = {
  type: typeof RECEIVE_STATS;
  payload: {
    globalStats?: WGlobalStatView[];
  };
};

type UpdateChecklistActionType = {
  type: typeof UPDATE_CHECKLIST;
};

type RequestTemplatesActionType = {
  type: typeof REQUEST_TEMPLATES;
};

type ReceiveTemplatesActionType = {
  type: typeof RECEIVE_TEMPLATES;
  payload: {
    templates: WChecklistTemplateView[];
  };
};

type AddTaskActionType = {
  type: typeof ADD_TASK;
};

type UpdateTaskActionType = {
  type: typeof UPDATE_TASK;
};

type RemoveTaskActionType = {
  type: typeof REMOVE_TASK;
  payload: {
    id: string;
  };
};

type ReceiveTaskActionType = {
  type: typeof RECEIVE_TASK;
  payload: {
    task: WChecklistTaskView;
  };
};

type ResetChecklistActionType = {
  type: typeof RESET_CHECKLIST;
};

type IncOnboardStepActionType = {
  type: typeof INC_ONBOARD_STEP;
};

type DecOnboardStepActionType = {
  type: typeof DEC_ONBOARD_STEP;
};

type ToggleOnboardActionType = {
  type: typeof TOGGLE_ONBOARD;
  payload: {
    show: boolean;
  };
};

type ToggleTemplatesActionType = {
  type: typeof TOGGLE_TEMPLATES;
  payload: number[];
};

type ReceiveChecklistV2ActionType = {
  type: typeof RECEIVE_CHECKLIST_V2;
  payload: {
    checklist: WChecklistViewV2;
  };
};

type SelectTaskActionType = {
  type: typeof SELECT_TASK;
  payload: {
    id: number;
  };
};

type UnselectTaskActionType = {
  type: typeof UNSELECT_TASK;
};

type ToggleTaskFilterActionType = {
  type: typeof TOGGLE_CHECKLIST_FILTER;
  payload: {
    filterValue: GetChecklistRequestV2.FiltersEnum;
  };
};

type ReplaceTaskFiltersActionType = {
  type: typeof REPLACE_CHECKLIST_FILTERS;
  payload: {
    filterValues: GetChecklistRequestV2.FiltersEnum[];
  };
};

type ClearFiltersActionType = {
  type: typeof CLEAR_CHECKLIST_FILTER;
};

type ToggleInvitePartnerViewActionType = {
  type: typeof TOGGLE_INVITE_PARTNER_VIEW;
  payload: {
    invitePartnerViewActive: boolean;
  };
};

type RequestTraditionsActionType = {
  type: typeof REQUEST_TRADITIONS;
};

type ReceiveTraditionsActionType = {
  type: typeof RECEIVE_TRADITIONS;
  payload: { templates: WChecklistTemplateView[] };
};

export type ChecklistActionTypes =
  | RequestChecklistActionType
  | ReceiveChecklistActionType
  | RequestStatsActionType
  | ReceiveStatsActionType
  | UpdateChecklistActionType
  | RequestTemplatesActionType
  | ReceiveTemplatesActionType
  | AddTaskActionType
  | UpdateTaskActionType
  | RemoveTaskActionType
  | ReceiveTaskActionType
  | ResetChecklistActionType
  | IncOnboardStepActionType
  | DecOnboardStepActionType
  | ToggleOnboardActionType
  | SelectTaskActionType
  | UnselectTaskActionType
  | ToggleTemplatesActionType
  | ReceiveChecklistV2ActionType
  | ToggleTaskFilterActionType
  | ReplaceTaskFiltersActionType
  | ClearFiltersActionType
  | ToggleInvitePartnerViewActionType
  | RequestTraditionsActionType
  | ReceiveTraditionsActionType;
