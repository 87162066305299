import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import GuestAffiliation from '../components/manage/guestlist/GuestAffiliation';
import * as ActionTypes from '../actions/types/SeatingChartActionTypes';

import {
  isGuestVisible,
  FACET_MATCHES_SHAPE,
  makeGuestsByID,
  makeCoupleObject,
} from './util/guestRailUtils';

// TODO: move 'facets' to a constant in the component
const initialState = {
  groupingsExpanded: {},
  couple: {},
  guestGroupings: null,
  filterState: false,
  currentGroupings: {},
  searchToken: '',
  groupingNames: {},
  guestsByGuestDistinctions: {},
  originalGroupsByGuestId: {},
  guestBySeatUuid: {},
  coupleBySeatUuid: {},
  searchState: false,
  activeFacetElements: [],
  guestsByID: {},
  facetMatches: Object.assign({}, FACET_MATCHES_SHAPE),
};

// TODO: remove 'guestGroupings': it's never read by components?
// TODO: remove invited/notInvited facet information
const guestRailReducer = (state = initialState, action) => {
  switch (action.type) {
    // couple, groupingNames, groupingsExpanded, guestGroupings, originalGroupsByGuestId
    case ActionTypes.RECEIVE_CHART: {
      const seatingChart = action.payload.data;
      const coupleObj = seatingChart.guests.couple;
      const guestGroupings = seatingChart.guests.guest_list;
      const { groupingNames, groupingsExpanded } = state;
      const originalGroupsByGuestId = {};

      Object.keys(guestGroupings).forEach((affiliation) => {
        let groupIndex = 0;
        guestGroupings[affiliation].forEach((guestGroup) => {
          guestGroup.guests.forEach((guest) => {
            originalGroupsByGuestId[guest.guest_view.id] = { guestGroup, groupIndex };
          });
          groupIndex += 1;
        });
      });

      const newGroupingNames = { WEDDING_COUPLE: 'The Happy Couple' };
      const newGroupingsExpanded = { WEDDING_COUPLE: true, ...groupingsExpanded };
      if (_isEmpty(groupingNames)) {
        const guestAffiliation = GuestAffiliation(
          coupleObj.primary_first_name,
          coupleObj.partner_first_name
        );

        Object.keys(guestGroupings).forEach((affiliation) => {
          newGroupingsExpanded[affiliation] = true;
        });

        for (let i = 0; i < guestAffiliation.length; i += 1) {
          const affiliation = guestAffiliation[i].value;
          newGroupingNames[affiliation] =
            affiliation === 'UNKNOWN' ? 'Other' : guestAffiliation[i].title;
        }
      }
      return {
        ...state,
        couple: makeCoupleObject(coupleObj),
        guestGroupings,
        originalGroupsByGuestId,
        groupingNames: _isEmpty(groupingNames) ? newGroupingNames : groupingNames,
        groupingsExpanded: _isEmpty(groupingNames) ? newGroupingsExpanded : groupingsExpanded,
        guestsByID: makeGuestsByID(guestGroupings),
      };
    }
    // couple, coupleBySeatUuid, currentGroupings, facets, guestsByGuestDistinction,
    // guestBySeatUuid, guestsByID,
    case ActionTypes.LOAD_GUEST_LIST: {
      const {
        activeFacetElements,
        guestGroupings,
        guestsByID: origGuestsByID,
        searchToken,
        couple: origCouple,
      } = state;
      const couple = Object.assign({}, origCouple);
      const facetMatches = {
        seated: [],
        notSeated: [],
        invited: [],
        notInvited: [],
        attending: [],
        undecided: [],
        declined: [],
      };

      // COUPLE: add isVisible
      couple.primary.isVisible = isGuestVisible(couple.primary, activeFacetElements, searchToken);
      couple.partner.isVisible = isGuestVisible(couple.partner, activeFacetElements, searchToken);

      // COUPLEBYSEATUUID: couple info
      const coupleBySeatUuid = {};
      if (couple.primary.seat_uuid != null) {
        coupleBySeatUuid[couple.primary.seat_uuid] = 'OWNER';
      }
      if (couple.partner.seat_uuid != null) {
        coupleBySeatUuid[couple.partner.seat_uuid] = 'PARTNER';
      }
      // facetMatches: add couple to proper facetMatches collection:
      const primarySeatUUID = couple.primary.seat_uuid;
      const partnerSeatUUID = couple.partner.seat_uuid;
      facetMatches.invited = facetMatches.invited.concat(['OWNER', 'PARTNER']);
      facetMatches.attending = facetMatches.attending.concat(['OWNER', 'PARTNER']);
      if (primarySeatUUID !== null) facetMatches.seated.concat(['OWNER']);
      if (primarySeatUUID === null) facetMatches.notSeated.concat(['OWNER']);
      if (partnerSeatUUID !== null) facetMatches.seated.concat(['PARTNER']);
      if (partnerSeatUUID === null) facetMatches.notSeated.concat(['PARTNER']);

      // currentGroupings: iterate through guest information
      const affiliations = Object.keys(guestGroupings);
      const currentGroupings = {};
      affiliations.forEach((affiliation) => {
        currentGroupings[affiliation] = [];
        // transform Affiliations
        guestGroupings[affiliation].forEach((group) => {
          const guestGroup = Object.assign({}, group);
          const updatedGuestGroup = [];
          // process Guests
          guestGroup.guests.forEach((g) => {
            const guest = { ...g, id: g.guest_view.id, couple_role: null };
            guest.isVisible = isGuestVisible(guest, activeFacetElements, searchToken);
            updatedGuestGroup.push(guest);
          });
          guestGroup.guests = updatedGuestGroup;
          currentGroupings[affiliation].push(guestGroup);
        });
      });

      // guestsByID: add guest visibility
      const guestsByID = _values(origGuestsByID).reduce((acc, guest) => {
        const isVisible = isGuestVisible(guest, activeFacetElements, searchToken);
        return { ...acc, [guest.id]: { ...guest, isVisible } };
      }, {});
      // add guests to facetMatches:
      _values(guestsByID).forEach((guest) => {
        const { id: guestID, rsvp_type: rsvpType, seat_uuid: seatUUID } = guest;
        if (seatUUID !== null) facetMatches.seated.push(guestID);
        if (seatUUID === null) facetMatches.notSeated.push(guestID);
        if (rsvpType === null) facetMatches.notInvited.push(guestID);
        if (rsvpType !== null) {
          facetMatches.invited.push(guestID);
          if (rsvpType === 'ATTENDING') facetMatches.attending.push(guestID);
          if (rsvpType === 'DECLINED') facetMatches.declined.push(guestID);
          if (rsvpType !== 'ATTENDING' && rsvpType !== 'DECLINED')
            facetMatches.undecided.push(guestID);
        }
      });
      // compose return values:
      const guestsByGuestDistinction = {
        guests: guestsByID,
        couple: { OWNER: couple.primary, PARTNER: couple.partner },
      };
      const guestBySeatUuid = _values(guestsByID).reduce(
        (acc, guest) => (guest.seat_uuid ? { ...acc, [guest.seat_uuid]: guest.id } : acc),
        {}
      );
      return {
        ...state,
        couple,
        coupleBySeatUuid,
        currentGroupings,
        guestBySeatUuid,
        guestsByGuestDistinction,
        guestsByID,
        facetMatches,
      };
    }
    // searchState, searchToken
    case ActionTypes.SEARCH_GUEST_LIST: {
      const { searchToken } = action.payload;
      const searchState = searchToken !== '';
      return { ...state, searchState, searchToken: searchToken.toLowerCase() };
    }
    // groupingsExpanded
    case ActionTypes.EXPAND_GUEST_GROUP: {
      const { guestGroup } = action.payload;
      const { groupingsExpanded } = state;
      return {
        ...state,
        groupingsExpanded: {
          ...groupingsExpanded,
          [guestGroup]: !groupingsExpanded[guestGroup],
        },
      };
    }
    // activeFacetElements
    case ActionTypes.TOGGLE_FACET_VALUE: {
      const { bucketType } = action.payload;
      const { activeFacetElements } = state;
      const hasBeenChecked = activeFacetElements.indexOf(bucketType) > -1;
      const newActiveFacetsElements = hasBeenChecked
        ? activeFacetElements.filter((i) => i !== bucketType)
        : activeFacetElements.concat([bucketType]);

      return {
        ...state,
        activeFacetElements: newActiveFacetsElements,
      };
    }
    // filterState
    case ActionTypes.TOGGLE_FILTER: {
      const { forceClose } = action.payload;
      return {
        ...state,
        filterState: forceClose ? false : !state.filterState,
      };
    }
    // couple, coupleBySeatUuid, guestGroupings, guestBySeatUuid
    // TODO: update guestsByID
    case ActionTypes.UPDATE_GUEST_LIST: {
      const { seats } = action.payload;
      if (seats.length === 0) return state;
      const {
        couple: origCouple,
        coupleBySeatUuid: origCoupleBySeatUUID,
        guestBySeatUuid: origGuestBySeatUUID,
        guestGroupings: origGuestGroupings,
        guestsByID,
      } = state;

      const couple = Object.assign({}, origCouple);
      const coupleBySeatUuid = Object.assign({}, origCoupleBySeatUUID);
      const guestGroupings = Object.assign({}, origGuestGroupings);
      const guestBySeatUuid = Object.assign({}, origGuestBySeatUUID);

      // We need to sort here to do the deleted changes first, otherwise we might set a guest
      // that moved table to table to the new seat uuid first, then null and they'd appear unseated even if they are seated
      seats.sort((seat1, seat2) => {
        if (seat1.guest_id == null && seat1.couple_role == null) return -1;
        if (seat2.guest_id == null && seat2.couple_role == null) return 1;
        return 0;
      });

      // check coupleBySeatUUID & guestbySeatUUID for seat matches
      seats.forEach((seat) => {
        const { uuid: seatUUID, guest_id: guestID, couple_role: coupleRole } = seat;
        // check for previous guest & unseat (guestBySeatUUID & guestGroupings)
        if (Object.prototype.hasOwnProperty.call(origGuestBySeatUUID, seatUUID)) {
          const prevGuestID = origGuestBySeatUUID[seat.uuid];
          const { affiliation } = guestsByID[prevGuestID];
          const groupID = guestsByID[prevGuestID].guest_view.guest_group_id;
          const newAffiliation = guestGroupings[affiliation].map((guestGroup) => {
            if (guestGroup.guest_group_id !== groupID) return guestGroup;
            const guestsArr = guestGroup.guests.map((guest) => {
              if (guest.guest_view.id !== prevGuestID) return guest;
              return { ...guest, table_uuid: null, seat_uuid: null };
            });
            return { ...guestGroup, guests: guestsArr };
          });
          guestGroupings[affiliation] = newAffiliation;
          delete guestBySeatUuid[seat.uuid];
        }
        // check for previous couple & unseat (couple & coupleBySeatUuid)
        if (Object.prototype.hasOwnProperty.call(origCoupleBySeatUUID, seatUUID)) {
          const key = origCoupleBySeatUUID[seatUUID] === 'OWNER' ? 'primary' : 'partner';
          couple[key] = { ...couple[key], table_uuid: null, seat_uuid: null };
          delete coupleBySeatUuid[seatUUID];
        }
        // seat new person: (couple & coupleBySeatUuid / guestGrouping & guestBySeatUuid)
        if (guestID !== null) {
          const { affiliation } = guestsByID[guestID];
          const groupID = guestsByID[guestID].guest_view.guest_group_id;
          const newAffiliation = guestGroupings[affiliation].map((guestGroup) => {
            if (guestGroup.guest_group_id !== groupID) return guestGroup;
            const guestArr = guestGroup.guests.map((guest) => {
              if (guest.guest_view.id !== guestID) return guest;
              return { ...guest, table_uuid: seat.table_uuid, seat_uuid: seatUUID };
            });
            return { ...guestGroup, guests: guestArr };
          });
          guestGroupings[affiliation] = newAffiliation;
          guestBySeatUuid[seatUUID] = guestID;
        }
        if (coupleRole !== null) {
          const key = coupleRole === 'OWNER' ? 'primary' : 'partner';
          couple[key] = { ...couple[key], table_uuid: seat.table_uuid, seat_uuid: seatUUID };
          coupleBySeatUuid[seatUUID] = coupleRole;
        }
      });
      return {
        ...state,
        couple,
        coupleBySeatUuid,
        guestGroupings,
        guestBySeatUuid,
      };
    }

    default:
      return state;
  }
};

export default guestRailReducer;
