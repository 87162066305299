import { CardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import {
  CustomizationStep,
  isCustomizationStepAllowingMultiples,
} from '@zola/zola-ui/src/paper/cards/constants/customizationSteps';

export const getStepKey = (id: CustomizationStep, cardType?: CardType | null) => {
  const needsType = isCustomizationStepAllowingMultiples(id) && !!cardType;

  return needsType ? (`${id}-${cardType}` as const) : id;
};
