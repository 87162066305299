import {
  INC_ONBOARD_STEP,
  DEC_ONBOARD_STEP,
  TOGGLE_ONBOARD,
  RESET_CHECKLIST,
} from '../../actions/types/ChecklistActionTypes';

const initialState = {
  active: false,
  step: 1,
  maxStep: 5,
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INC_ONBOARD_STEP: {
      const step = state.step + 1;
      const active = state.active && step <= state.maxStep;
      return Object.assign({}, state, { step, active });
    }
    case DEC_ONBOARD_STEP: {
      const step = state.step - 1;
      const active = state.active && step <= state.maxStep;
      return Object.assign({}, state, { step, active });
    }
    case TOGGLE_ONBOARD: {
      return Object.assign({}, state, { active: action.payload.show });
    }
    case RESET_CHECKLIST: {
      return initialState;
    }
    default:
      return state;
  }
};

export default templatesReducer;
