import type { WQuestionView, WQuestionnaireView } from '@zola/svc-web-api-ts-client';
import type { BusinessUnitComponent } from '~/components/onboard/types';
import type { Dayjs } from 'dayjs';
import * as ActionTypes from '../actions/types/OnboardActionTypes';

export interface QuestionnaireStateMap extends Omit<WQuestionnaireView, 'questions'> {
  questions: Partial<{ [key: string]: Pick<WQuestionView, 'question_id' | 'question_key'> }>;
}

type OnboardReducerStateType = {
  isSlugAvailable: boolean;
  // New Onboard Flow:
  businessUnitComponent: BusinessUnitComponent | '';
  questions: [];
  isSigningUp: boolean;
  questionsLoaded: boolean;
  currentQuestionNumber: 0;
  questionNumber: 0;
  isDeferSelectProduct: boolean;
  submitDelayActive: boolean;
  // New Wedding planning flow
  cityState: string;
  weddingYear?: number | null;
  weddingMonth?: number | null;
  questionnaire: QuestionnaireStateMap;
  date?: Dayjs;
};

export const initialState: OnboardReducerStateType = {
  isSlugAvailable: true,
  // New Onboard Flow:
  businessUnitComponent: '',
  questions: [],
  isSigningUp: false,
  questionsLoaded: false,
  currentQuestionNumber: 0,
  questionNumber: 0,
  isDeferSelectProduct: false,
  submitDelayActive: false,
  // New Wedding planning flow
  cityState: '',
  weddingYear: undefined,
  weddingMonth: undefined,
  questionnaire: { questions: {} },
  date: undefined,
};

type MappedQuestion = {
  question_key?: string;
  question_id?: number;
};

// TODO: Update the any to use specific OnboardActions
// eslint-disable-next-line
const onboardReducer = (state = initialState, action: any): OnboardReducerStateType => {
  switch (action.type) {
    case ActionTypes.SLUG_AVAILABILITY_RECEIVED: {
      return Object.assign({}, state, { isSlugAvailable: action.payload });
    }
    case ActionTypes.SLUG_SUGGESTIONS_RECEIVED: {
      return Object.assign({}, state, { slugSuggestions: action.payload });
    }
    // New Perfect Wedding Flow actions
    case ActionTypes.RECEIVE_QUESTIONS: {
      const questionMap =
        action.payload.questions?.reduce(
          (acc: Record<string, MappedQuestion>, q: WQuestionView) => {
            const { question_id, question_key } = q;
            return question_key ? { ...acc, [question_key]: { question_id, question_key } } : acc;
          },
          {} as Record<string, MappedQuestion>
        ) || {};
      return {
        ...state,
        questionsLoaded: true,
        ...action.payload,
        questionnaire: { ...action.payload, questions: questionMap },
      };
    }
    case ActionTypes.REQUEST_QUESTIONS: {
      return { ...state, questionsLoaded: false };
    }
    case ActionTypes.REQUEST_SIGNUP: {
      return { ...state, isSigningUp: true };
    }
    case ActionTypes.ONBOARDING_COMPLETED: {
      return { ...state, isSigningUp: false, ...action.payload.data };
    }
    case ActionTypes.UPDATE_WEDDING_DATE: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.SET_BUSINESS_UNIT_COMPONENT: {
      return Object.assign({}, state, { businessUnitComponent: action.payload });
    }
    case ActionTypes.UPDATE_SUBMIT_DELAY_ACTIVE: {
      return Object.assign({}, state, { submitDelayActive: action.payload });
    }
    case ActionTypes.SET_CITY_STATE: {
      return Object.assign({}, state, { cityState: action.payload });
    }
    case ActionTypes.SET_WEDDING_YEAR: {
      return Object.assign({}, state, { weddingYear: action.payload });
    }
    case ActionTypes.SET_WEDDING_MONTH: {
      return Object.assign({}, state, { weddingMonth: action.payload });
    }
    case ActionTypes.SUBMIT_QUESTIONNAIRE_ANSWERS: {
      const { questionKey, values } = action.payload;
      return Object.assign({}, state, {
        questionnaire: {
          ...state.questionnaire,
          questions: {
            ...state.questionnaire.questions,
            [action.payload.questionKey]: {
              ...state.questionnaire.questions[questionKey],
              values,
            },
          },
        },
      });
    }
    case ActionTypes.CLEAR_QUESTIONNAIRE_ANSWER: {
      const { questionKey } = action.payload;
      return Object.assign({}, state, {
        questionnaire: {
          ...state.questionnaire,
          questions: {
            ...state.questionnaire.questions,
            [action.payload.questionKey]: {
              ...state.questionnaire.questions[questionKey],
              values: [],
            },
          },
        },
      });
    }
    default:
      return state;
  }
};

export default onboardReducer;
