import type {
  WProjectFullView,
  WProjectCustomizationElementView,
  WProjectCustomizationFullView,
} from '@zola/svc-web-api-ts-client';
import {
  UpdateQRCodeStateAction,
  UPDATE_QR_CODE_STATE,
  UpdatePageQRCodeAction,
  UPDATE_PAGE_QR_CODE,
} from '~/cards/actions/qrCodeActionTypes';
import { ProjectActionTypes } from '~/cards/actions/projectActionTypes';

type QRSettings = {
  type: string;
  color: string;
  qrCodeURL: string;
};

export type PageQRCodeData = {
  /* A page's QR section can be enabled without being generated yet */
  enabled: boolean;
  /* Have there been changes since the last time the QR was generated? */
  changedSinceGeneration: boolean;
  /* User has explicitly acknowledged that their QR is valid */
  reviewed: boolean;
  /* The element associated with the latest generated QR code */
  qrElement: WProjectCustomizationElementView | null;
  /* The latest QR settings returned from the BE */
  latestSavedSettings: QRSettings;
} & QRSettings;

export interface QRCodeState {
  loading: boolean;
  mobileGatewaySeen: boolean | null;
  byPageUUID: Record<string, PageQRCodeData>;
}

const initialState: QRCodeState = {
  loading: false,
  mobileGatewaySeen: null,
  byPageUUID: {},
};

interface CustomizationReceiveAction {
  type: typeof ProjectActionTypes.RECEIVE_CUSTOMIZATION;
  payload: { rawCustomization: WProjectCustomizationFullView };
}
interface ProjectReceiveAction {
  type: typeof ProjectActionTypes.RECEIVE_PROJECT;
  payload: { isTestProject: boolean; project: WProjectFullView };
}

const isQRElement = (element: WProjectCustomizationElementView): boolean =>
  !!element?.tag?.trim()?.startsWith('QR_CODE::');

const extractQRDataByPageId = (
  customizations: WProjectCustomizationFullView[],
  state: QRCodeState
) => {
  const qrDataByPageUUID: Record<string, PageQRCodeData> = {};
  customizations.forEach((customization) => {
    customization.pages?.forEach((page) => {
      if (page.uuid) {
        const qrElement = page?.elements?.find(isQRElement);
        const qrDataString = qrElement?.tag
          ?.trim()
          ?.split('QR_CODE::')
          ?.find((s?: string) => !!s);
        const qrData = qrDataString && JSON.parse(qrDataString);

        if (qrElement && qrData) {
          const type = qrData.url_type;
          const color = qrData.color || '000000';
          const qrCodeURL = qrData.url || '';

          qrDataByPageUUID[page.uuid] = {
            ...state.byPageUUID[page.uuid],
            changedSinceGeneration: false,
            enabled: qrData.enabled,
            type,
            color,
            qrCodeURL,
            latestSavedSettings: { type, color, qrCodeURL },
            qrElement,
          };
        } else {
          const previousData = state.byPageUUID[page.uuid];
          const type = previousData?.type || '';
          const color = previousData?.color || '000000';
          const qrCodeURL = previousData?.qrCodeURL || '';

          qrDataByPageUUID[page.uuid] = {
            changedSinceGeneration: false,
            reviewed: false,
            enabled: false,
            qrElement: null,
            type,
            color,
            qrCodeURL,
            latestSavedSettings: { type, color, qrCodeURL },
          };
        }
      }
    });
  });
  return qrDataByPageUUID;
};

const WATCHED_KEYS: (keyof QRSettings)[] = ['type', 'qrCodeURL', 'color'];

const qrCodeReducer = (
  state = initialState,
  action:
    | UpdateQRCodeStateAction
    | UpdatePageQRCodeAction
    | ProjectReceiveAction
    | CustomizationReceiveAction
): QRCodeState => {
  switch (action.type) {
    case UPDATE_QR_CODE_STATE: {
      return { ...state, ...action.payload };
    }
    case UPDATE_PAGE_QR_CODE: {
      const { pageUUID, data } = (action as UpdatePageQRCodeAction).payload;
      const previousPageData = state.byPageUUID[pageUUID];
      const savedData = previousPageData.latestSavedSettings;
      const newPageData = {
        ...previousPageData,
        ...data,
      };
      const changedSinceGeneration = WATCHED_KEYS.some((k) => newPageData[k] !== savedData[k]);

      return {
        ...state,
        byPageUUID: {
          ...state.byPageUUID,
          [pageUUID]: {
            ...newPageData,
            changedSinceGeneration,
          },
        },
      };
    }
    case ProjectActionTypes.RECEIVE_CUSTOMIZATION: {
      const customization = (action as CustomizationReceiveAction).payload?.rawCustomization;
      if (!customization) return state;
      const qrDataByPageUUID = extractQRDataByPageId([customization], state);
      return {
        ...state,
        byPageUUID: {
          ...state.byPageUUID,
          ...qrDataByPageUUID,
        },
      };
    }
    case ProjectActionTypes.RECEIVE_PROJECT: {
      const project = (action as ProjectReceiveAction).payload?.project;
      if (!project?.customizations) return state;
      return {
        ...state,
        byPageUUID: extractQRDataByPageId(project.customizations, state),
      };
    }
    default: {
      return state;
    }
  }

  return state;
};

export default qrCodeReducer;
