import includes from 'lodash/includes';

import type { GetChecklistRequestV2, WGlobalStatView } from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../../actions/types/ChecklistActionTypes';
import type { ChecklistActionTypes } from '../../actions/types/ChecklistActionTypes';

export type OptionType = { value?: string; label?: string };

export type ChecklistChecklistReducerStateType = {
  busy: boolean;
  filters: GetChecklistRequestV2.FiltersEnum[];
  facets: { options: OptionType[] }[];
  noFilterResults: boolean;
  invitePartnerViewActive: boolean;

  // TODO: where are these used?
  initialized: boolean;
  id: string | null;
  isDetailed: boolean;
  isCompleted: boolean;
  templateIds: string[];
  globalStats: WGlobalStatView[];
  checklistUsers: unknown[];
};

const initialState: ChecklistChecklistReducerStateType = {
  busy: false,
  initialized: false,
  id: null,
  isDetailed: false,
  isCompleted: false,
  templateIds: [],
  globalStats: [],
  checklistUsers: [],
  filters: [],
  noFilterResults: false,
  invitePartnerViewActive: false,
  facets: [],
};

const checklistReducer = (
  state = initialState,
  action: ChecklistActionTypes
): ChecklistChecklistReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_CHECKLIST: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_CHECKLIST: {
      const { checklist } = action.payload;

      return Object.assign(
        {},
        state,
        { id: checklist.id },
        { isDetailed: checklist.is_detailed },
        { templateIds: checklist.template_ids },
        { globalStats: checklist.global_stats },
        { checklistUsers: checklist.checklist_users },
        { busy: false, initialized: true }
      );
    }
    case ActionTypes.RECEIVE_CHECKLIST_V2: {
      const { checklist } = action.payload;
      const filteredResults = checklist.checklist_task_groups;

      const areResults = filteredResults?.some((group) => group.tasks?.length);

      return Object.assign({}, state, {
        id: checklist.id,
        isCompleted: checklist.completed,
        isDetailed: checklist.detailed,
        templateIds: checklist.template_ids,
        globalStats: checklist.global_stats,
        checklistUsers: checklist.checklist_users,
        facets: checklist.facets,
        busy: false,
        initialized: true,
        noFilterResults: !areResults,
      });
    }
    case ActionTypes.REQUEST_STATS: {
      return state;
    }
    case ActionTypes.RECEIVE_STATS: {
      return Object.assign({}, state, { globalStats: action.payload.globalStats });
    }
    case ActionTypes.RESET_CHECKLIST: {
      return initialState;
    }
    case ActionTypes.TOGGLE_CHECKLIST_FILTER: {
      const clonedFilters = [...state.filters];
      const { filterValue: newFilterValue } = action.payload;

      if (includes(clonedFilters, newFilterValue)) {
        const stateWithoutFilterValue = clonedFilters.filter(
          (filterValue) => filterValue !== newFilterValue
        );

        return {
          ...state,
          filters: stateWithoutFilterValue,
        };
      }

      clonedFilters.push(newFilterValue);

      return {
        ...state,
        filters: clonedFilters,
      };
    }
    case ActionTypes.CLEAR_CHECKLIST_FILTER: {
      return {
        ...state,
        noFilterResults: false,
        filters: [],
      };
    }
    case ActionTypes.REPLACE_CHECKLIST_FILTERS: {
      const { filterValues } = action.payload;

      return {
        ...state,
        filters: [...filterValues],
      };
    }
    case ActionTypes.TOGGLE_INVITE_PARTNER_VIEW: {
      const { invitePartnerViewActive } = action.payload;

      return {
        ...state,
        invitePartnerViewActive,
      };
    }
    default:
      return state;
  }
};

export default checklistReducer;
