import type { WUploadedImageView } from '@zola/svc-web-api-ts-client';

export const FILE_UPLOADING = 'zola/file/FILE_UPLOADING';
export const FILE_UPLOADED = 'zola/file/FILE_UPLOADED';

type RequestFileUploadActionType = {
  type: typeof FILE_UPLOADING;
};

export type ReceiveFileUploadActionType = {
  type: typeof FILE_UPLOADED;
  payload: { fileData: WUploadedImageView };
};

export type FileUploadActionTypes = RequestFileUploadActionType | ReceiveFileUploadActionType;
