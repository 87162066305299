import * as ActionTypes from '../actions/types/AuthActionTypes';

const initialState = {
  email: null,
  isAuthenticated: false,
  isAuthenticating: false,
  businessUnit: undefined,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST: {
      return Object.assign({}, state, {
        isAuthenticating: true,
        statusText: null,
      });
    }
    case ActionTypes.LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        email: action.payload.email,
      });
    }
    case ActionTypes.LOGIN_FAILURE: {
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: false,
        email: null,
      });
    }
    case ActionTypes.LOGOUT: {
      return Object.assign({}, state, {
        isAuthenticated: false,
        email: null,
      });
    }
    case ActionTypes.SET_BUSINESS_UNIT: {
      return Object.assign({}, state, {
        businessUnit: action.payload,
      });
    }
    default:
      return state;
  }
};

export default authReducer;
