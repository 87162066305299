import * as ActionTypes from '../actions/types/ExternalActionTypes';
import type {
  ExternalActionType,
  ExternalReducerStateType,
} from '../actions/types/ExternalActionTypes';

const initialState: ExternalReducerStateType = {
  giftGiverName: null,
  purchasedInStore: false,
  orderNumber: null,
  isConfirmed: false,
  noOrderNum: false,
};

const externalReducer = (state = initialState, action: ExternalActionType) => {
  switch (action.type) {
    case ActionTypes.UPDATE_EXTERNAL: {
      return Object.assign({}, state, action.payload);
    }
    case ActionTypes.PLACED_ORDER: {
      const { splitOrderId, token } = action.payload.data;
      return Object.assign({}, state, { splitOrderId, token });
    }
    case ActionTypes.CANCELLED_ORDER: {
      return initialState;
    }
    case ActionTypes.CONFIRMED_ORDER: {
      return initialState;
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default externalReducer;
