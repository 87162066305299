import * as ActionTypes from '../../actions/types/EventActionTypes';

const initialState = {
  busy: false,
  suggestions: [],
};

const rsvpReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUGGESTIONS_REQUESTED: {
      return { ...state, busy: true };
    }
    case ActionTypes.SUGGESTIONS_RECEIVED: {
      const suggestions = action.payload;
      return { ...state, busy: false, suggestions };
    }
    default:
      return state;
  }
};

export default rsvpReducer;
