export const CardCatalogActionTypes = {
  REQUEST_SEARCH_CARD_SUITES: 'zola/cards/card_catalog/REQUEST_SEARCH_CARD_SUITES',
  RECEIVE_SEARCH_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_SEARCH_CARD_SUITES',
  RECEIVE_SEARCH_CARD_SUITES_BY_UUID: 'zola/cards/card_catalog/RECEIVE_SEARCH_CARD_SUITES_BY_UUID',
  // Card suites that are matched to the user based on the theme they selected for their wedding website
  RECEIVE_THEME_MATCHED_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_THEME_MATCHED_CARD_SUITES',
  RECEIVE_CARD: 'zola/cards/card_catalog/RECEIVE_CARD',
  UPDATE_CARD_CUSTOMIZATION_UUID: 'zola/cards/card_catalog/UPDATE_CARD_CUSTOMIZATION_UUID',
  UPDATE_SEARCH_FILTERS: 'zola/cards/card_catalog/UPDATE_SEARCH_FILTER',
  UPDATE_MULTIPLE_SEARCH_FILTERS: 'zola/cards/card_catalog/UPDATE_MULTIPLE_SEARCH_FILTERS',
  OVERWRITE_SEARCH_FILTERS: 'zola/cards/card_catalog/OVERWRITE_SEARCH_FILTERS',
  RESET_SEARCH_FILTERS: 'zola/cards/card_catalog/RESET_SEARCH_FILTERS',
  UPDATE_CATALOG_ADMIN_VIEW: 'zola/cards/card_catalog/UPDATE_CATALOG_ADMIN_VIEW',
  RECEIVE_ADDITIONAL_SUITE_VERSIONS: 'zola/cards/card_catalog/RECEIVE_ADDITIONAL_SUITE_VERSIONS',
  RECEIVE_RELATED_CARDS_FROM_SUITE: 'zola/cards/card_catalog/RECEIVE_RELATED_CARDS_FROM_SUITE',
  RESET_RELATED_CARDS_FROM_SUITE: 'zola/cards/card_catalog/RESET_RELATED_CARDS_FROM_SUITE',
  RECEIVE_RELATED_CARDS_BY_DESIGNER: 'zola/cards/card_catalog/RECEIVE_RELATED_CARDS_BY_DESIGNER',
  RECEIVE_PAPER_ADD_ONS: 'zola/cards/card_catalog/RECEIVE_PAPER_ADD_ONS',
  RESET_RELATED_CARDS_BY_DESIGNER: 'zola/cards/card_catalog/RESET_RELATED_CARDS_BY_DESIGNER',
  RESET_PAPER_ADD_ONS: 'zola/cards/card_catalog/RESET_PAPER_ADD_ONS',
  RECEIVE_COLLABORATORS: 'zola/cards/card_catalog/RECEIVE_COLLABORATORS',
  HYDRATE_MOTIFS: 'zola/cards/card_catalogs/HYDRATE_MOTIFS',
  RECEIVE_PREVIOUS_SEARCH_RESULTS: 'zola/cards/card_catalog/RECEIVE_PREVIOUS_SEARCH_RESULTS',
  RECEIVE_ADDITIONAL_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_ADDITIONAL_CARD_SUITES',
  RECEIVE_INITIAL_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_INITIAL_CARD_SUITES',
  RESET_THEME_MATCHED_CARDS: 'zola/cards/card_catalog/RESET_THEME_MATCHED_CARDS',
  SET_MULTISAMPLE_SELECTED: 'zola/card/project/SET_MULTISAMPLE_SELECTED',
  CLEAR_MULTISAMPLE_SELECTED: 'zola/card/project/CLEAR_MULTISAMPLE_SELECTED',
  SET_MULTISAMPLE_ENABLED: 'zola/card/project/SET_MULTISAMPLE_ENABLED',
};

export default CardCatalogActionTypes;
