import type { WChecklistTaskView } from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../../actions/types/ChecklistActionTypes';
import type { ChecklistActionTypes } from '../../actions/types/ChecklistActionTypes';

import * as checklistHelpers from '../../components/manage/ChecklistV2/helpers';
import { notEmpty } from '../../util/typeUtil';

export type WChecklistTaskViewWithGroupType = WChecklistTaskView & { group?: string };

export type ChecklistTaskGroupType = {
  label?: string;
  taskIds: number[];
};

export type ChecklistTasksByIdType = { [id: string]: WChecklistTaskViewWithGroupType | undefined };

export type ChecklistTaskReducerStateType = {
  filteredTasksGroups: ChecklistTaskGroupType[];
  byId: ChecklistTasksByIdType;
  selectedId: string | null;
};
const initialState: ChecklistTaskReducerStateType = {
  byId: {},
  selectedId: null,
  filteredTasksGroups: [],
};

type WChecklistTaskViewWithId = WChecklistTaskView & {
  id: number;
};

function hasId(task: WChecklistTaskView): task is WChecklistTaskViewWithId {
  return Boolean(task.id);
}

const tasksReducer = (
  state = initialState,
  action: ChecklistActionTypes
): ChecklistTaskReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_CHECKLIST: {
      return state;
    }
    case ActionTypes.RECEIVE_CHECKLIST: {
      const taskGroups = action.payload.checklist.checklist_task_groups || [];
      const byId: ChecklistTasksByIdType = {};
      const groups: ChecklistTaskGroupType[] = [];

      taskGroups.forEach((taskGroup) => {
        const { tasks } = taskGroup;

        groups.push({
          label: taskGroup.label,
          taskIds: tasks?.map((task) => task.id).filter(notEmpty) || [],
        });

        (tasks || []).filter(hasId).forEach((task) => {
          byId[task.id.toString()] = {
            ...task,
            group: taskGroup.label,
          };
        });
      });

      return Object.assign({}, state, { byId, groups });
    }
    case ActionTypes.RECEIVE_CHECKLIST_V2: {
      const filteredTasksGroups = (action.payload.checklist.checklist_task_groups || []).filter(
        (taskGroup) => taskGroup.tasks?.length
      );
      const byId: ChecklistTasksByIdType = {};
      const groups: ChecklistTaskGroupType[] = [];

      filteredTasksGroups.forEach((taskGroup) => {
        const { tasks } = taskGroup;

        groups.push({
          label: taskGroup.label,
          taskIds: tasks?.map((task) => task.id).filter(notEmpty) || [],
        });

        (tasks || []).filter(hasId).forEach((task) => {
          byId[task.id.toString()] = {
            ...task,
            group: taskGroup.label,
          };
        });
      });

      return Object.assign({}, state, { byId, filteredTasksGroups: groups });
    }
    case ActionTypes.RESET_CHECKLIST: {
      return initialState;
    }
    case ActionTypes.ADD_TASK: {
      // Todo: instead of refetching, insert new task into state
      return state;
    }
    case ActionTypes.UPDATE_TASK: {
      // Todo: instead of refetching, insert updated task into state
      return state;
    }
    case ActionTypes.REMOVE_TASK: {
      // Todo: instead of refetching, delete task from state
      return state;
    }
    case ActionTypes.RECEIVE_TASK: {
      const newTask = action.payload.task;

      if (!hasId(newTask)) {
        return state;
      }

      // TODO: This endpoint removes checklist_template_name for some reason; Endpoint should return checklist_template_name for individual tasks
      const persistTaskTemplateName = state.byId[newTask.id]?.checklist_template_name;
      const byId = { ...state.byId };

      byId[newTask.id] = {
        ...newTask,
        checklist_template_name: persistTaskTemplateName,
        group: checklistHelpers.getTaskGroup(newTask.due_at),
      };

      return Object.assign({}, state, { byId });
    }
    case ActionTypes.SELECT_TASK: {
      return Object.assign({}, state, { selectedId: action.payload.id });
    }
    case ActionTypes.UNSELECT_TASK: {
      return Object.assign({}, state, { selectedId: null });
    }
    default:
      return state;
  }
};

export default tasksReducer;
