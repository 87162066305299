import type { WGuestCollectionDetailView } from '@zola/svc-web-api-ts-client';

export const REQUEST_GUEST_COLLECTION = 'zola/guest-collection/REQUEST_GUEST_COLLECTION';
export const RECEIVE_GUEST_COLLECTION = 'zola/guest-collection/RECEIVE_GUEST_COLLECTION';
export const REQUEST_GUEST_COLLECTION_CREATE_UPDATE =
  'zola/guest-collection/REQUEST_GUEST_COLLECTION_CREATE_UPDATE';
export const CLEAR_GUEST_COLLECTION_TEMP_IMG =
  'zola/guest-collection/CLEAR_GUEST_COLLECTION_TEMP_IMG';
export const CLEAR_GUEST_COLLECTION_SAVED_IMG =
  'zola/guest-collection/CLEAR_GUEST_COLLECTION_SAVED_IMG';
export const RECEIVE_NO_GUEST_COLLECTION = 'zola/guest-collection/RECEIVE_NO_GUEST_COLLECTION';

type RequestGuestCollectionActionType = {
  type: typeof REQUEST_GUEST_COLLECTION;
};

type ReceiveGuestCollectionActionType = {
  type: typeof RECEIVE_GUEST_COLLECTION;
  payload: WGuestCollectionDetailView;
};

type RequestGuestCollectionCreationOrUpdateActionType = {
  type: typeof REQUEST_GUEST_COLLECTION_CREATE_UPDATE;
};

type ReceiveNoGuestCollectionActionType = {
  type: typeof RECEIVE_NO_GUEST_COLLECTION;
};

type ClearGuestCollectionTempImgActionType = {
  type: typeof CLEAR_GUEST_COLLECTION_TEMP_IMG;
};

type ClearGuestCollectionSavedImgActionType = {
  type: typeof CLEAR_GUEST_COLLECTION_SAVED_IMG;
};

export type GuestCollectionActionTypes =
  | RequestGuestCollectionActionType
  | ReceiveGuestCollectionActionType
  | RequestGuestCollectionCreationOrUpdateActionType
  | ClearGuestCollectionTempImgActionType
  | ClearGuestCollectionSavedImgActionType
  | ReceiveNoGuestCollectionActionType;
