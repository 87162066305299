export const TrackingActionTypes = {
  TRACK_CARD_CUSTOMIZATION_EVENT: 'zola/cards/tracking/TRACK_CARD_CUSTOMIZATION_EVENT',
  TRACK_CARD_LIST_VIEWED: 'zola/cards/tracking/TRACK_CARD_LIST_VIEWED',
  TRACK_CARD_PRODUCT_CLICKED: 'zola/cards/tracking/TRACK_CARD_PRODUCT_CLICKED',
  TRACK_CARD_PRODUCT_ADDED_TO_CART: 'zola/cards/tracking/TRACK_CARD_PRODUCT_ADDED_TO_CART',
  TRACK_TYNG_RECOMMENDATION_VIEWED: 'zola/cards/tracking/TRACK_TYNG_RECOMMENDATION_VIEWED',
  TRACK_TYNG_RECOMMENDATION_SELECTED: 'zola/cards/tracking/TRACK_TYNG_RECOMMENDATION_SELECTED',
};

export default TrackingActionTypes;
