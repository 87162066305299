import {
  SORT,
  Status,
  PlacecardGuestListState,
  PlacecardGuestListAction,
  REQUEST_PLACECARD_GUEST_LIST,
  RECEIVE_PLACECARD_GUEST_LIST,
  SET_PLACECARD_GUEST_LIST_EDIT,
  SET_PLACECARD_GUEST_LIST_SORT_BY,
  SET_PLACECARD_GUEST_LIST_PREVIEW_GUEST,
} from './types';

const init: PlacecardGuestListState = {
  status: Status.INIT,
  guestList: null,
  previewGuest: null,
  sortBy: { primary: 'table', name: SORT.ASC, table: SORT.ASC },
  edit: null,
};

const MAX_INPUT_LENGTH = 99;

const reducer = (
  state: PlacecardGuestListState = init,
  action: PlacecardGuestListAction
): PlacecardGuestListState => {
  switch (action.type) {
    case REQUEST_PLACECARD_GUEST_LIST: {
      return { ...state, status: Status.LOADING };
    }
    case RECEIVE_PLACECARD_GUEST_LIST: {
      return { ...state, status: Status.DONE, guestList: action.payload || null };
    }
    case SET_PLACECARD_GUEST_LIST_EDIT: {
      if (!action.payload) {
        return { ...state, edit: null };
      }

      return {
        ...state,
        edit: {
          ...(state.edit || {}),
          ...action.payload,
          name: (typeof action.payload.name === 'string'
            ? action.payload.name
            : state?.edit?.name || ''
          ).substring(0, MAX_INPUT_LENGTH),
          table: (typeof action.payload.table === 'string'
            ? action.payload.table
            : state?.edit?.table || ''
          ).substring(0, MAX_INPUT_LENGTH),
        },
      };
    }
    case SET_PLACECARD_GUEST_LIST_SORT_BY: {
      return { ...state, sortBy: action.payload || null };
    }
    case SET_PLACECARD_GUEST_LIST_PREVIEW_GUEST: {
      return { ...state, previewGuest: action.payload || null };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
