import * as ActionTypes from '../actions/types/RegistryBulkImportActionTypes';

const registryBulkImportReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_OTHER_REGISTRIES: {
      return Object.assign({}, state, { otherRegistries: action.data.schedules });
    }
    case ActionTypes.VALIDATE_URL_ERROR: {
      return Object.assign({}, state, { submitUrlError: action.data });
    }
    default:
      return state;
  }
};

export default registryBulkImportReducer;
