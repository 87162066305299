import {
  REQUEST_TEMPLATES,
  RECEIVE_TEMPLATES,
  RESET_CHECKLIST,
  REQUEST_TRADITIONS,
  RECEIVE_TRADITIONS,
  TOGGLE_TEMPLATES,
} from '../../actions/types/ChecklistActionTypes';

import alphabetizeTraditions from '../../util/alphabetizeTraditions';

const initialState = {
  byId: {},
  levelToId: {},
  selectedTemplates: [],
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TRADITIONS:
    case REQUEST_TEMPLATES: {
      return state;
    }
    case RECEIVE_TRADITIONS:
    case RECEIVE_TEMPLATES: {
      let { templates } = action.payload;
      const byId = {};
      const levelToId = {};

      templates = alphabetizeTraditions(templates);

      templates.forEach((template) => {
        byId[template.id] = template;
        if (levelToId[template.level] === undefined) {
          levelToId[template.level] = [];
        }
        levelToId[template.level].push(template.id);
      });

      return Object.assign({}, state, { byId, levelToId });
    }
    case RESET_CHECKLIST: {
      return initialState;
    }
    case TOGGLE_TEMPLATES: {
      return Object.assign({}, state, {
        selectedTemplates: action.payload,
      });
    }
    default:
      return state;
  }
};

export default templatesReducer;
