import {
  physicalSlides,
  cardSlides,
  cashSlides,
  honeymoonSlides,
} from '../components/Customization/TyGenerator/TYCContentSuggestion/copy';
import { State, Action } from '../components/Customization/TyGenerator/TYCContentSuggestion/types';

export const init: State = {
  expanded: false,
  gift: 'Nothing',
  group: physicalSlides,
  index: 0,
};

export const tycContentSuggestion = (state: State = init, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_GIFTS':
      return {
        ...state,
        expanded: !state.expanded,
      };
    case 'SET_GIFT_NOTHING':
      return {
        ...state,
        gift: 'Nothing',
      };
    case 'SET_GIFT_PHYSICAL':
      return {
        ...state,
        gift: 'Physical',
        group: physicalSlides,
        index: 0,
      };
    case 'SET_GIFT_CASH':
      return {
        ...state,
        gift: 'Cash',
        group: cashSlides,
        index: 0,
      };
    case 'SET_GIFT_CARD':
      return {
        ...state,
        gift: 'Card',
        group: cardSlides,
        index: 0,
      };
    case 'SET_GIFT_HONEYMOON':
      return {
        ...state,
        gift: 'Honeymoon',
        group: honeymoonSlides,
        index: 0,
      };
    case 'NEXT_SLIDE': {
      const nextIndex = state.index + 1;
      return {
        ...state,
        index: state.group.slides[nextIndex] ? nextIndex : 0,
      };
    }
    case 'PREV_SLIDE': {
      const prevIndex = state.index - 1;
      return {
        ...state,
        index: state.group.slides[prevIndex] ? prevIndex : state.group.slides.length - 1,
      };
    }
    default:
      return state;
  }
};
