export const CHECK_SLUG_AVAILABLE = 'zola/onboard/CHECK_SLUG_AVAILABLE';
export const SLUG_AVAILABILITY_RECEIVED = 'zola/onboard/SLUG_AVAILABILITY_RECEIVED';
export const SLUG_SUGGESTIONS_RECEIVED = 'zola/onboard/SLUG_SUGGESTIONS_RECEIVED';
export const REQUEST_UPDATE_SLUG = 'zola/onboard/REQUEST_UPDATE_SLUG';
export const RECEIVE_UPDATED_SLUG = 'zola/onboard/RECEIVE_UPDATED_SLUG';

// New Perfect Onboarding Flow ActionTypes:

export const REQUEST_QUESTIONS = 'zola/onboard/REQUEST_QUESTIONS';
export const RECEIVE_QUESTIONS = 'zola/onboard/RECEIVE_QUESTIONS';
export const UPDATE_QUESTION_NUMBER = 'zola/onboard/UPDATE_QUESTION_NUMBER';
export const REQUEST_SIGNUP = 'zola/onboard/REQUEST_SIGNUP';
export const ONBOARDING_COMPLETED = 'zola/onboard/ONBOARDING_COMPLETED';
export const UPDATE_WEDDING_DATE = 'zola/onboard/UPDATE_WEDDING_DATE';
export const ACCOUNT_CREATED = 'zola/account/created';
export const SET_BUSINESS_UNIT_COMPONENT = 'zola/onboard/SET_BUSINESS_UNIT_COMPONENT';
export const SET_DEFER_SELECT_PRODUCT = 'zola/onboard/SET_DEFER_SELECT_PRODUCT';

export const UPDATE_SUBMIT_DELAY_ACTIVE = 'zola/onboard/UPDATE_SUBMIT_DELAY_ACTIVE';

export const CLEAR_QUESTIONNAIRE_ANSWER = 'zola/onboard/CLEAR_QUESTIONNAIRE_ANSWER';
export const SUBMIT_QUESTIONNAIRE_ANSWERS = 'zola/onboard/SUBMIT_QUESTIONNAIRE_ANSWERS';

export const CLEAR_CITY_STATE = 'zola/onboard/CLEAR_CITY_STATE';
export const SET_CITY_STATE = 'zola/onboard/SET_CITY_STATE';
export const SET_WEDDING_YEAR = 'zola/onboard/SET_WEDDING_YEAR';
export const SET_WEDDING_MONTH = 'zola/onboard/SET_WEDDING_MONTH';
