export const NotesActionTypes = {
  REQUEST_GUESTS_WITH_CUSTOM_NOTE: 'zola/cards/project/REQUEST_GUESTS_WITH_CUSTOM_NOTE',
  RECEIVE_GUESTS_WITH_CUSTOM_NOTE: 'zola/cards/project/RECEIVE_GUESTS_WITH_CUSTOM_NOTE',
  RECEIVE_GUEST_NOTE: 'zola/cards/project/RECEIVE_GUEST_NOTE',
  UPDATE_GUEST_NOTE: 'zola/cards/project/UPDATE_GUEST_NOTE',
  DELETE_GUEST_NOTE: 'zola/cards/project/DELETE_GUEST_NOTE',
  REQUEST_GUEST_DEFAULT_NOTE: 'zola/cards/project/REQUEST_GUEST_DEFAULT_NOTE',
  // RECEIVE_GUEST_DEFAULT_NOTE: 'zola/cards/project/RECEIVE_GUEST_DEFAULT_NOTE',
  SET_ACTIVE_GUEST_GROUP_UUID: 'zola/cards/project/SET_ACTIVE_GUEST_GROUP_UUID',
  RESET_GUESTS_NOTES: 'zola/cards/project/RESET_GUESTS_NOTES',
};

export default NotesActionTypes;
