import { combineReducers } from 'redux';
import events from './events';
import rsvp from './rsvp';
import migration from './migration';

const eventsManager = combineReducers({
  events,
  rsvp,
  migration,
});

export type EventsReducerStateType = ReturnType<typeof eventsManager>;

export default eventsManager;
