import { getTypeFromBackendType } from '~/cards/util/cardTypes';
import { AnyAction } from 'redux';
import {
  ActiveSelection,
  PageData,
  ImageData,
  Cost,
  PaperInfo,
} from '~/cards/pages/ProductDetail/ProductDetailTypes';
import type {
  WCardShotKeyImageView,
  WCardSuiteDetailsView,
  WCardTemplateView,
  WNextVariationView,
} from '@zola/svc-web-api-ts-client';
import { AvailableOptions, OptionItem } from '~/cards/types/options';
import { Orientation } from '~/cards/types/orientation';

const orderedEnabledMediums = ['paper', 'magnet', 'digital'];

const sortAndUpdateLabels = (availableOptions: AvailableOptions) => {
  const updatedOptions = Object.keys(availableOptions).reduce((acc, key) => {
    const optionGroup = availableOptions[key];
    acc[key] = {
      ...optionGroup,
      label: key === 'color' ? 'Theme' : optionGroup.label,
      options: optionGroup.options.sort((a, b) => (a.position || 0) - (b.position || 0)),
    };
    return acc;
  }, {} as AvailableOptions);

  return updatedOptions;
};

interface PdpReducerState {
  busy: boolean;
  error: null;
  data: PageData | null;
  mediums: Record<string, any>;
  activeSuiteUUID: string;
  activeMediumUUID: string;
  activeVariationUUID: string;
  activeMediumKey: string;
  activeSelection: ActiveSelection;
  images: Record<string, ImageData>;
  shotKeyImagesByColor: Record<string, WCardShotKeyImageView[]>;
  thumbnailsByColor: Record<string, WCardShotKeyImageView>;
  cost: Cost;
  quantity: number;
  variationTemplates: Record<string, WCardTemplateView>;
  showOnboardModal: boolean;
  availableOptions: AvailableOptions;
  earliestArrivalDate: string;
}

const init: PdpReducerState = {
  busy: true,
  error: null,
  data: null,
  mediums: {},
  activeSuiteUUID: '',
  activeMediumUUID: '',
  activeVariationUUID: '',
  activeMediumKey: 'paper',
  activeSelection: {} as ActiveSelection,
  images: {},
  shotKeyImagesByColor: {},
  thumbnailsByColor: {},
  cost: {
    base: 0,
    extras: 0,
    total: 0,
  },
  quantity: 100,
  variationTemplates: {},
  showOnboardModal: false,
  availableOptions: {},
  earliestArrivalDate: '',
};

const reducer = (state: PdpReducerState = init, action: AnyAction): PdpReducerState => {
  switch (action.type) {
    case 'SET_ACTIVE_SUITE':
      return {
        ...state,
        activeSuiteUUID: action.payload,
      };
    case 'CARD_SUITE_FETCH':
      return {
        ...state,
        busy: true,
        error: null,
      };
    case 'CARD_SUITE_FETCH_FULFILLED': {
      const {
        collaborator,
        customizable: isCustomizable,
        description = '',
        family = '',
        hasCustomPhoto,
        orientation = 'PORTRAIT',
        imagesUrls,
        shotKeyImagesByColor,
        thumbnails: thumbnailsByColor,
        mediums,
        motifs = [],
        name = '',
        paperInfo,
        foil: isStampFoilCard,
        theme,
        type = '',
        singleSampleSku = '',
        singleSampleAvailable,
        uuid = '',
        promotionCode: promotionData,
      } = action.payload as WCardSuiteDetailsView;

      if (!mediums || !paperInfo || !imagesUrls || !uuid) return state;

      // Choose appropriate medium (per order in orderedEnabledMediums)
      const activeMediumKey =
        mediums.magnet?.selectedVariationOptions?.['paper-type'] === 'magnet'
          ? 'magnet'
          : (orderedEnabledMediums.find((key) => Object.keys(mediums).includes(key)) as string);

      const activeMediumData = mediums[activeMediumKey];
      const {
        availableOptions,
        cardSkuDataView,
        defaultVariationOptions,
        defaultVariationUuid,
        selectedVariationOptions,
        selectedVariationUuid,
        uuid: activeMediumUUID,
      } = activeMediumData;

      if (
        !availableOptions ||
        !cardSkuDataView ||
        !defaultVariationOptions ||
        !defaultVariationUuid ||
        !activeMediumUUID
      ) {
        return state;
      }

      const updatedOptions = sortAndUpdateLabels(availableOptions as AvailableOptions);

      const foilOptions = updatedOptions.foil?.options || [];
      const isCustomFoilAvailable = foilOptions.some((o) => o.value === 'custom' && o.enabled);
      const quantity = paperInfo.qty_default || 1;
      const minPrice = (paperInfo.price_cents_min || 0) / 100;
      const currentPrice = (cardSkuDataView.priceCents || 0) / 100;

      return {
        ...state,
        busy: false,
        mediums: mediums || {},
        activeMediumKey,
        images: imagesUrls as Record<string, ImageData>,
        shotKeyImagesByColor: shotKeyImagesByColor || {},
        thumbnailsByColor: thumbnailsByColor || {},
        data: {
          loadedSuiteUUID: uuid,
          collaborator,
          isCustomizable,
          description,
          family,
          hasCustomPhoto: !!hasCustomPhoto,
          motifs,
          orientation: orientation as Orientation,
          name,
          paperInfo: paperInfo as PaperInfo,
          cardType: getTypeFromBackendType(type), // Mapping back-end value to front-end value
          websiteTheme: theme,
          isStampFoilCard: !!isStampFoilCard,
          isCustomFoilAvailable,
          singleSampleSku,
          singleSampleAvailable: !!singleSampleAvailable,
          promotionData,
        },
        quantity,
        cost: {
          ...state.cost,
          base: minPrice,
          extras: currentPrice,
          total: quantity * currentPrice,
        },
        activeMediumUUID,
        activeVariationUUID: selectedVariationUuid || defaultVariationUuid,
        availableOptions: updatedOptions,
        activeSelection: {
          ...(defaultVariationOptions as ActiveSelection),
          ...selectedVariationOptions,
          type: activeMediumKey,
        },
        earliestArrivalDate: cardSkuDataView.earliestArrivalDate?.toString() || '',
      };
    }
    case 'CARD_SUITE_FETCH_REJECTED':
      return {
        ...state,
        error: action.payload,
      };
    case 'NEXT_VARIATION_RECEIVED': {
      const {
        nextVariation,
        changedOptionKey,
        changedOption,
      }: {
        nextVariation: WNextVariationView;
        changedOptionKey?: string;
        changedOption?: OptionItem;
      } = action.payload;

      const {
        cardVariationUuid: activeVariationUUID,
        cardSkuDataView,
        availableOptions,
      } = nextVariation;

      // Must always run AFTER suite details have been fetched
      if (!state.data || !activeVariationUUID || !cardSkuDataView || !availableOptions) {
        return state;
      }

      // The availableOptions object returned by getNextVariation doesn't contain option metadata (BE limitation),
      // but the original suite data DOES. Combine the corresponding metadata with the new available color options.
      const previousAvailableOptions = state.availableOptions;
      if (availableOptions.color?.options && previousAvailableOptions.color?.options) {
        availableOptions.color.options = availableOptions.color.options.map((newOption) => {
          const previousOption = previousAvailableOptions.color.options.find(
            (o) => o.value === newOption.value
          );
          return {
            ...newOption,
            metadata: previousOption?.metadata || newOption.metadata,
          };
        });
      }

      const updatedOptions = sortAndUpdateLabels(availableOptions as AvailableOptions);
      const currentPrice = (cardSkuDataView.priceCents || 0) / 100;
      const foilOptions = updatedOptions?.foil?.options || [];
      const isCustomFoilAvailable = foilOptions.some((o) => o.value === 'custom' && o.enabled);

      const backendSelection = Object.values(updatedOptions).reduce((acc, optionGroup) => {
        const { key, options } = optionGroup;
        const currentOptionValue = options?.find((o) => o.selected)?.value;

        if (key && currentOptionValue) {
          acc[key as keyof ActiveSelection] = currentOptionValue;
        }
        return acc;
      }, {} as ActiveSelection);

      // Medium updates (if necessary)
      let mediumUpdates = {};
      if (
        changedOptionKey === 'type' &&
        changedOption?.value &&
        changedOption.value !== state.activeMediumKey
      ) {
        mediumUpdates = {
          activeMediumKey: changedOption.value,
          activeMediumUUID: changedOption?.card_uuid,
        };
      }

      return {
        ...state,
        activeVariationUUID,
        availableOptions: updatedOptions,
        activeSelection: {
          ...state.activeSelection,
          ...backendSelection,
        },
        data: {
          ...(state.data || {}),
          isCustomFoilAvailable,
        },
        cost: {
          ...state.cost,
          extras: currentPrice,
          total: state.quantity * currentPrice,
        },
        earliestArrivalDate: cardSkuDataView.earliestArrivalDate?.toString() || '',
        ...mediumUpdates,
      };
    }
    case 'SET_QUANTITY': {
      const newQuantity = action.payload as number;
      return {
        ...state,
        quantity: newQuantity,
        cost: {
          ...state.cost,
          total: newQuantity * state.cost.extras,
        },
      };
    }
    case 'FETCH_VARIATION_TEMPLATE_SUCCESS': {
      const { variationUUID, template } = action.payload;
      return {
        ...state,
        variationTemplates: {
          ...state.variationTemplates,
          [variationUUID]: template || null,
        },
      };
    }
    case 'SET_SHOW_ONBOARD_MODAL': {
      return {
        ...state,
        showOnboardModal: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
