export const REQUEST_BANNER = 'zola/wedding/website/banner/REQUEST_BANNER';
export const RECEIVE_BANNER_LIST = 'zola/wedding/website/banner/RECEIVE_BANNER_LIST';
export const RECEIVE_BANNER_ID = 'zola/wedding/website/banner/RECEIVE_BANNER_ID';
export const RECEIVE_BANNER_MESSAGE = 'zola/wedding/website/banner/RECEIVE_BANNER_MESSAGE';

type RequestBannerType = {
  type: typeof REQUEST_BANNER;
};

type ReceiveBannerMessageType = {
  type: typeof RECEIVE_BANNER_MESSAGE;
  payload: { bannerMessage?: string };
};

export type BannerActionTypes = RequestBannerType | ReceiveBannerMessageType;
