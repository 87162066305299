import {
  PLPPhotoPreviewAction,
  UPDATE_PLP_PHOTO_PREVIEW_STATE,
} from '~/cards/actions/plpPhotoPreview';

export interface SessionData {
  initialized: boolean;
  currentUploadcareFile: any | null;
  currentFlowUuid: string | null;

  /*
   * Since we don't persist the user choice on enabling the PLP Photo Preview feature
   * when having to check for the different values like the CDN url or the Flow UUID
   * we need to assume the feature is ON by default, until useEffect is triggered
   * with the proper card type to effectively confirm the feature status - or not - for the
   * current card type, based on the session storage values.
   *
   * This is especially needed for triggering tracking events at the right time
   * and avoid multiple triggers.
   */
  isInUseForCardType: boolean;
}

export interface PLPPhotoPreviewState {
  cardType: string | null;
  uploadcareFile: any | null;
  sessionData: SessionData;
}

const initialState: PLPPhotoPreviewState = {
  cardType: null,
  uploadcareFile: null,
  sessionData: {
    initialized: false,
    currentFlowUuid: null,
    currentUploadcareFile: null,
    isInUseForCardType: true,
  },
};

const plpPhotoPreviewReducer = (
  state = initialState,
  action: PLPPhotoPreviewAction
): PLPPhotoPreviewState => {
  switch (action.type) {
    case UPDATE_PLP_PHOTO_PREVIEW_STATE: {
      return { ...state, ...(action.payload || {}) };
    }
    default: {
      return state;
    }
  }

  return state;
};

export default plpPhotoPreviewReducer;
