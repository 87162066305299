const flattenGuests = (guestArray) =>
  guestArray.reduce(
    (guest, acc) => ({
      ...acc,
      [guest.guest_view.id]: {
        displayName: guest.display_name,
        seatUUID: guest.seat_uuid,
        tableUUID: guest.table_uuid,
        groupID: guest.guest_view.guest_group_id,
      },
    }),
    {}
  );

const flattenGroupArray = (groupArray) =>
  groupArray.reduce((acc, groupObj) => {
    const guestCollection = flattenGuests(groupObj.guests);
    return {
      ...acc,
      ...guestCollection,
    };
  }, {});

export const createSearchSet = (guestGroups) => {
  const affiliationSet = Object.keys(guestGroups);
  return affiliationSet.reduce((acc, affiliationName) => {
    const affiliationObjects = flattenGroupArray(guestGroups[affiliationName]);
    return { ...acc, ...affiliationObjects };
  }, {});
};

export const reorderArrayItem = (arr, startIndex, targetIndex) => {
  const front = arr.slice(0, startIndex);
  const back = arr.slice(startIndex + 1);
  const cleanArr = front.concat(back);
  const newFront = cleanArr.slice(0, targetIndex);
  const newBack = cleanArr.slice(targetIndex);
  const item = arr[startIndex];
  return newFront.concat([item], newBack);
};

export const filler = null;
