import { useEffect } from 'react';
import { usePrevious } from '@zola/zola-ui/src/hooks/usePrevious';
import { withRouter, NextRouter } from 'next/router';

/**
 * This component relies on Segment (analytics.js) to trigger a page view on route changes.
 * It is included within the main _app and intended only for NextJs pages because it relies on the next/router.
 */
type TrackPageViewProps = {
  router: NextRouter;
};

export const TrackPageViewComponent = ({ router }: TrackPageViewProps): null => {
  const { asPath } = router;
  const queryParams = asPath.split('?')[1];
  const path = asPath.split('?')[0];
  const search = queryParams ? `?${queryParams}` : undefined;

  useEffect(() => {
    const { referrer } = document;
    if (window && window.zola) {
      window.zola.referrer = referrer;
    }
  });

  const prevSearch = usePrevious(search);
  const prevPath = usePrevious(path);

  useEffect(() => {
    const isDifferentPathname = path !== prevPath;
    const isDifferentSearch = search !== prevSearch;
    const referrer = window?.zola?.referrer ? window.zola.referrer : document.referrer;
    if (window && window.zola) {
      // Adds referrer on a global object to avoid the hassle of getting past and passing referrer to every component that calls an event.
      window.zola.referrer = referrer;
    }
    if (isDifferentPathname || isDifferentSearch) {
      if (window.analytics) {
        window.analytics.page({
          path,
          search,
          referrer,
          url: window.location.href,
          title: document && document.title,
        });
      }
    }
  }, [path, search, prevPath, prevSearch]);

  return null;
};

export default withRouter(TrackPageViewComponent);
