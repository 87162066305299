import ActionTypes from '../actions/modalActionTypes';

const initialState = {
  isVisible: false,
  modalType: null,
  modalProps: {},
  modalOptions: {},
  busy: false,
};

export default function modalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.HIDE_MODAL:
      return {
        ...state,
        modalType: null,
        modalProps: {},
        modalOptions: {},
        isVisible: false,
        busy: false,
      };
    case ActionTypes.OPEN_MODAL:
      return {
        ...state,
        isVisible: true,
        busy: false,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
        modalOptions: action.payload.modalOptions,
      };
    case ActionTypes.UPDATE_MODAL_OPTIONS:
      return {
        ...state,
        modalOptions: { ...(state.modalOptions || {}), ...(action.payload || {}) },
      };
    case ActionTypes.IS_BUSY:
      return {
        ...state,
        busy: action.payload,
      };
    default:
      return state;
  }
}
