import type { WGetCartView } from '@zola/svc-web-api-ts-client';
import type { CamelCasedPropertiesDeep } from 'type-fest';

export const REQUEST_SIZE = 'zola/cart/REQUEST_SIZE' as const;
export const RECEIVE_SIZE = 'zola/cart/RECEIVE_SIZE' as const;
export const REQUEST_CART = 'zola/cart/REQUEST_CART' as const;
export const RECEIVE_CART = 'zola/cart/RECEIVE_CART' as const;
export const RESET_CART = 'zola/cart/RESET_CART' as const;

export type CartType = CamelCasedPropertiesDeep<WGetCartView>;

export type RequestCartSizeActionType = {
  type: typeof REQUEST_SIZE;
};

export type ReceiveCartSizeActionType = {
  type: typeof RECEIVE_SIZE;
  payload: number;
};

export type RequestCartActionType = {
  type: typeof REQUEST_CART;
};

export type ReceiveCartActionType = {
  type: typeof RECEIVE_CART;
  payload: CartType;
};

export type ResetCartActionType = {
  type: typeof RESET_CART;
};

export type CartActionTypes =
  | RequestCartSizeActionType
  | ReceiveCartSizeActionType
  | RequestCartActionType
  | ReceiveCartActionType
  | ResetCartActionType;
