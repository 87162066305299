import _filter from 'lodash/filter';

export function isCompleteAddress(address) {
  const { countryCode, address1, city, state, zip } = address;
  if (countryCode === 'US' || countryCode === 'CA') {
    return !!(address1 && city && state && zip && countryCode);
  }
  return !!(address1 && city && zip && countryCode);
}

export function isMissingAddress(address) {
  const { countryCode, address1, city, state, zip } = address;
  if (countryCode === 'US' || countryCode === 'CA') {
    return !(address1 || city || zip || state);
  }
  return !(address1 || city || zip);
}

export function isNameUnknown(guest) {
  return !guest.first_name && !guest.family_name;
}

export function getIncompleteAddressGuestGroupIds(guestGroups) {
  if (guestGroups.length === 0) return [];

  return _filter(guestGroups, (guestGroup) => {
    const address = {
      address1: guestGroup.address_1,
      address2: guestGroup.address_2,
      city: guestGroup.city,
      state: guestGroup.state_province,
      zip: guestGroup.postal_code,
      countryCode: guestGroup.country_code,
    };
    return !isCompleteAddress(address);
  }).map((guestGroup) => guestGroup.id);
}

export function getMissingAddressPropertyGuestGroupIds(guestGroups, addressProperty) {
  if (!guestGroups.length) return [];

  return _filter(guestGroups, (guestGroup) => {
    if (!['US', 'CA'].includes(guestGroup.country_code) && addressProperty === 'state_province') {
      return false;
    }

    return !guestGroup[addressProperty];
  }).map((guestGroup) => guestGroup.id);
}
