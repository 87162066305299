import { CARD_TYPE_MAP, CardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';

export const getTypeFromBackendType = (backEndType: string): CardType => {
  switch (backEndType) {
    case 'HOLIDAY':
      return CARD_TYPE_MAP.holiday;
    case 'THANK_YOU':
      return CARD_TYPE_MAP.thankyou;
    case 'SAVE_THE_DATE':
      return CARD_TYPE_MAP.std;
    case 'CHANGE_THE_DATE':
      return CARD_TYPE_MAP.ctd;
    case 'INVITATION':
      return CARD_TYPE_MAP.invitation;
    case 'DETAILS':
      return CARD_TYPE_MAP.enclosure;
    case 'MENU':
      return CARD_TYPE_MAP.menu;
    case 'PROGRAM':
      return CARD_TYPE_MAP.program;
    case 'PLACE':
      return CARD_TYPE_MAP.place;
    case 'WEDDING_SHOWER_INVITATION':
      return CARD_TYPE_MAP.weddingShower;
    default:
      return backEndType as CardType;
  }
};

export const getBackendTypeFromType = (cardType: CardType): string => {
  switch (cardType) {
    case CARD_TYPE_MAP.dstd:
      return 'SAVE_THE_DATE';
    case CARD_TYPE_MAP.enclosure:
      return 'DETAILS';
    case CARD_TYPE_MAP.invitationEnvelope:
      return 'ENVELOPE';
    default:
      return cardType;
  }
};
