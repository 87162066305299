import type { WProjectListingViewByType, WProjectOrderView } from '@zola/svc-web-api-ts-client';

export const DraftsAndOrdersActionTypes = {
  RECEIVE_CANCELLED_ORDER: 'zola/cards/draftsAndOrders/RECEIVE_CANCELLED_ORDER',
  MOVE_PUBLISHED_TO_DRAFTS: 'zola/cards/draftsAndOrders/MOVE_PUBLISHED_TO_DRAFTS',
  REQUEST_PROJECTS: 'zola/cards/draftsAndOrders/REQUEST_PROJECTS',
  RECEIVE_PROJECTS: 'zola/cards/draftsAndOrders/RECEIVE_PROJECTS',
  DELETE_DRAFT: 'zola/cards/draftsAndOrders/DELETE_DRAFTS',
  DELETE_PUBLISHED: 'zola/cards/draftsAndOrders/DELETE_PUBLISHED',
  SET_ACTIVE_DRAFTS_TAB: 'zola/cards/draftsAndOrders/SET_ACTIVE_DRAFTS_KEY',
  SET_ACTIVE_SEGMENT: 'zola/cards/draftsAndOrders/SET_ACTIVE_SEGMENT_KEY',
  SET_ACTIVE_ANCHOR: 'zola/cards/draftsAndOrders/SET_ACTIVE_ANCHOR',
  TOGGLE_WAITING_ON_PREVIEW_SUITES: 'zola/card/draftsAndOrders/TOGGLE_WAITING_ON_PREVIEW_SUITES',
};

export interface DeleteDraft {
  type: typeof DraftsAndOrdersActionTypes.DELETE_DRAFT;
  payload: { projectUUID: string; medium: string; cardType: string };
}

export interface DeletePublished {
  type: typeof DraftsAndOrdersActionTypes.DELETE_PUBLISHED;
  payload: { projectUUID: string };
}

export interface MovePublishedToDrafts {
  type: typeof DraftsAndOrdersActionTypes.MOVE_PUBLISHED_TO_DRAFTS;
  payload: { projectUUID: string };
}

export interface RequestProjects {
  type: typeof DraftsAndOrdersActionTypes.RECEIVE_PROJECTS;
  payload: any;
}

export interface ProjectsData extends WProjectListingViewByType {
  waitingOnPreviewSuites: boolean | undefined;
  entities: {
    customizations: WProjectOrderView[];
    projects: WProjectOrderView[];
  };
}

export interface ReceiveProjects {
  type: typeof DraftsAndOrdersActionTypes.RECEIVE_PROJECTS;
  payload: { projects: ProjectsData };
}

export interface ReceiveCancelledOrder {
  type: typeof DraftsAndOrdersActionTypes.RECEIVE_CANCELLED_ORDER;
  payload: { cancelledOrder: AnalyserNode };
}

export interface SetActiveDraftsTab {
  type: typeof DraftsAndOrdersActionTypes.SET_ACTIVE_DRAFTS_TAB;
  payload: { key: string };
}

export interface SetActiveSegment {
  type: typeof DraftsAndOrdersActionTypes.SET_ACTIVE_SEGMENT;
  payload: { key: string };
}

export interface SetActiveAnchor {
  type: typeof DraftsAndOrdersActionTypes.SET_ACTIVE_ANCHOR;
  payload: { anchor: string | null };
}

export interface ToggleWaitingOnPreviewSuites {
  type: typeof DraftsAndOrdersActionTypes.TOGGLE_WAITING_ON_PREVIEW_SUITES;
  payload: { waitingOnPreviewSuites: boolean };
}

export type DraftsAndOrdersAction =
  | DeleteDraft
  | DeletePublished
  | MovePublishedToDrafts
  | RequestProjects
  | ReceiveProjects
  | ReceiveCancelledOrder
  | ToggleWaitingOnPreviewSuites;

export default DraftsAndOrdersActionTypes;
