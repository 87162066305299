export const ProjectActionTypes = {
  SET_CURRENT_PATHNAME: 'zola/card/project/SET_CURRENT_PATHNAME',
  RECEIVE_CUSTOMIZATION: 'zola/card/project/RECEIVE_CUSTOMIZATION',
  RECEIVE_CUSTOMIZATION_ERRORS: 'zola/card/project/RECEIVE_CUSTOMIZATION_ERRORS',
  RECEIVE_ADD_TEXT_ELEMENT: 'zola/card/project/RECEIVE_ADD_TEXT_ELEMENT',
  RECEIVE_RESTORED_ELEMENT: 'zola/card/project/RECEIVE_RESTORED_ELEMENT',
  RECEIVE_DELETE_ELEMENT: 'zola/card/project/RECEIVE_DELETE_ELEMENT',
  RECEIVE_DELETE_ELEMENT_IMAGE: 'zola/card/project/RECEIVE_DELETE_ELEMENT_IMAGE',
  RECEIVE_PROJECT_AUTHENTICATION: 'zola/card/project/RECEIVE_PROJECT_AUTHENTICATION',
  RECEIVE_PROJECT_GUEST_IDS: 'zola/card/project/guests/RECEIVE_PROJECT_GUEST_IDS',
  RECEIVE_PROJECT_GUESTS: 'zola/card/project/guests/RECEIVE_PROJECT_GUESTS',
  RECEIVE_PROJECT: 'zola/cards/project/RECEIVE_PROJECT',
  RECEIVE_PROJECT_ERRORS: 'zola/cards/project/RECEIVE_PROJECT_ERRORS',
  CLEAR_PROJECT_ERROR: 'zola/cards/project/CLEAR_PROJECT_ERROR',
  REQUEST_CUSTOMIZATION: 'zola/cards/project/REQUEST_CUSTOMIZATION',
  REQUEST_PROJECT_AUTHENTICATION: 'zola/cards/project/REQUEST_PROJECT_AUTHENTICATION',
  REQUEST_PROJECT_GUEST_IDS: 'zola/cards/project/guests/REQUEST_PROJECT_GUEST_IDS',
  REQUEST_PROJECT: 'zola/cards/project/REQUEST_PROJECT',
  SET_ACTIVE_FIELD: 'zola/cards/project/SET_ACTIVE_FIELD',
  GET_CUSTOMIZATION_FONTS_REQUEST: 'zola/cards/project/GET_CUSTOMIZATION_FONTS_REQUEST',
  GET_CUSTOMIZATION_FONTS_SUCCESS: 'zola/cards/project/GET_CUSTOMIZATION_FONTS_SUCCESS',
  GET_CUSTOMIZATION_FONTS_FAILURE: 'zola/cards/project/GET_CUSTOMIZATION_FONTS_FAILURE',
  REQUEST_ADD_PROJECT_TO_CART: 'zola/cards/project/REQUEST_ADD_PROJECT_TO_CART',
  RECEIVE_ADD_PROJECT_TO_CART: 'zola/cards/project/RECEIVE_ADD_PROJECT_TO_CART',
  REQUEST_PROJECT_META: 'zola/cards/project/REQUEST_PROJECT_META',
  RECEIVE_PROJECT_META: 'zola/cards/project/RECEIVE_PROJECT_META',
  COMPLETE_FONT_RESIZE: 'zola/cards/project/COMPLETE_FONT_RESIZE',
  OVERRIDE_FONT_SIZES: 'zola/cards/project/OVERRIDE_FONT_SIZES',
  ADD_TO_FOIL_HISTORY: 'zola/cards/project/ADD_TO_FOIL_HISTORY',
  REMOVE_ONE_FROM_FOIL_HISTORY: 'zola/cards/project/REMOVE_ONE_FROM_FOIL_HISTORY',
  ADD_TO_DELETE_HISTORY: 'zola/cards/project/ADD_TO_DELETE_HISTORY',
  REMOVE_ONE_FROM_DELETE_FOIL_HISTORY: 'zola/cards/project/REMOVE_ONE_FROM_DELETE_FOIL_HISTORY',
  UPDATE_IMAGE_SIZE_REQUIREMENTS: 'zola/cards/project/UPDATE_IMAGE_SIZE_REQUIREMENTS',
  UPDATE_PLACE_CARD_CUSTOMIZATION: 'zola/cards/project/UPDATE_PLACE_CARD_CUSTOMIZATION',
  CHECK_FREE_SAMPLE_AVAILABILITY: 'zola/cards/project/CHECK_FREE_SAMPLE_AVAILABILITY',
  UPDATE_DIGITAL_DETAILS: 'zola/cards/project/UPDATE_DIGITAL_DETAILS',
  SET_PROJECT_STEPS: 'zola/card/project/SET_PROJECT_STEPS',
  UPDATE_PROJECT_STEP: 'zola/card/project/UPDATE_PROJECT_STEP',
  RECEIVE_PDF_REQUEST_DETAILS: 'zola/card/project/RECEIVE_PDF_REQUEST_DETAILS',
  REQUEST_PDF_REQUEST_DETAILS: 'zola/card/project/REQUEST_PDF_REQUEST_DETAILS',
};

export default ProjectActionTypes;
