export const UPDATE_EXTERNAL = 'zola/external/UPDATE_EXTERNAL';
export const PLACED_ORDER = 'zola/external/PLACED_ORDER';
export const CONFIRMED_ORDER = 'zola/external/CONFIRMED_ORDER';
export const CANCELLED_ORDER = 'zola/external/CANCELLED_ORDER';
export const RESET = 'zola/external/RESET';

// https://github.com/NewAmsterdamLabs/web-zola/blob/development/app/controllers/api/v0/ExternalOrderApiController.scala#L36
export type ExternalOrderConfirmData = {
  quantity: number;
  giftGiverName: string;
  purchasedInStore: boolean;
  externalStoreName?: string;
  orderNumber?: string | null;
};

// https://github.com/NewAmsterdamLabs/web-zola/blob/development/app/controllers/api/v0/ExternalOrderApiController.scala#L44
export type ExternalOrderPlaceData = {
  collectionItemId: string;
  quantity: number;
  giftGiverName?: string | null;
  giftGiverEmail: string;
  isConfirmed: boolean;
  purchasedInStore: boolean;
  orderNumber?: string | null;
  externalStoreName?: string;
  itemOrigin?: string;
};

export type ExternalOrderPlacedReturnType = {
  data: {
    splitOrderId: string;
    token: string;
  };
};

export type ExternalReducerStateType = Partial<ExternalOrderPlaceData> &
  Partial<ExternalOrderPlacedReturnType['data']> & {
    noOrderNum?: boolean;
    collectionItemId?: string;
  };

// TODO: MISMATCH
type UpdateExternalActionType = {
  type: typeof UPDATE_EXTERNAL;
  payload: ExternalReducerStateType;
};

type PlacedOrderActionType = {
  type: typeof PLACED_ORDER;
  payload: ExternalOrderPlacedReturnType;
};

// OTOD: MISMATCH
type ConfirmedOrderActionType = {
  type: typeof CONFIRMED_ORDER;
};

type CancelledOrderActionType = {
  type: typeof CANCELLED_ORDER;
};

type ResetActionType = {
  type: typeof RESET;
};

export type ExternalActionType =
  | UpdateExternalActionType
  | PlacedOrderActionType
  | ConfirmedOrderActionType
  | CancelledOrderActionType
  | ResetActionType;
