import type { CardTemplateBackgroundView } from '@zola/svc-web-api-ts-client';
import {
  ExtraCustomizationActions,
  REQUEST_BACKGROUND_ASSETS,
  RECEIVE_BACKGROUND_ASSETS,
} from '~/cards/actions/extraCustomizationActionTypes';

type BackgroundAssetDataByKey = {
  promise: Promise<CardTemplateBackgroundView[]>;
  assets?: CardTemplateBackgroundView[];
};

export interface ExtraCustomizationState {
  backgroundAssetDataByKey: Record<string, BackgroundAssetDataByKey>;
}

const initialState: ExtraCustomizationState = {
  backgroundAssetDataByKey: {},
};

const extraCustomizationReducer = (
  state = initialState,
  action: ExtraCustomizationActions
): ExtraCustomizationState => {
  switch (action.type) {
    case REQUEST_BACKGROUND_ASSETS: {
      const { key, promise } = action.payload;
      return {
        ...state,
        backgroundAssetDataByKey: {
          ...state.backgroundAssetDataByKey,
          [key]: {
            promise,
            assets: undefined,
          },
        },
      };
    }
    case RECEIVE_BACKGROUND_ASSETS: {
      const { key, assets } = action.payload;
      return {
        ...state,
        backgroundAssetDataByKey: {
          ...state.backgroundAssetDataByKey,
          [key]: {
            ...state.backgroundAssetDataByKey[key],
            assets,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default extraCustomizationReducer;
