import {
  GET_PRODUCTS,
  LOADING_PRODUCTS,
  PRODUCTS_ERROR,
  PRODUCTS_LOADED,
  RESET_PRODUCTS,
} from '../actions/productActionTypes';

const initialState = {
  loading: false,
  loaded: false,
  products: [],
  error: null,
};

const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_PRODUCTS:
      return { ...initialState };
    case GET_PRODUCTS:
      return { ...state };
    case LOADING_PRODUCTS:
      return { ...state, loading: true };
    case PRODUCTS_LOADED:
      return { ...state, loading: false, loaded: true, products: payload };
    case PRODUCTS_ERROR:
      return { ...state, loading: false, loaded: true, products: [], error: payload };
    default:
      return state;
  }
};

export default productReducer;
