import type { QRCodeState, PageQRCodeData } from '~/cards/reducers/qrCode';

export const UPDATE_PAGE_QR_CODE = 'UPDATE_PAGE_QR_CODE';
export interface UpdatePageQRCodeAction {
  type: typeof UPDATE_PAGE_QR_CODE;
  payload: {
    pageUUID: string;
    data: Partial<PageQRCodeData>;
  };
}

export const UPDATE_QR_CODE_STATE = 'UPDATE_QR_CODE_STATE';
export interface UpdateQRCodeStateAction {
  type: typeof UPDATE_QR_CODE_STATE;
  payload: Partial<QRCodeState>;
}
