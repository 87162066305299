import * as ActionTypes from '../../actions/types/GuestActionTypes';
import { isCompleteAddress, isMissingAddress } from '../../components/manage/guestlist/util';

const initialState = {
  initialized: false,
  busy: false,
  byId: {},
  orderedIds: [],
  facets: [],
  selectedFacetValues: {},
  globalStats: [],
  search: [],
  sortByNameAsc: true,
  sortByValue: 'FAMILY_NAME',
  ascending: true,
  activeEventTab: null,
  altActiveGlobal: false,
};

const constructSearch = (guestGroups) =>
  Object.keys(guestGroups).map((key) => ({
    id: guestGroups[key].id,
    names: guestGroups[key].guests
      .map((guest) => {
        const firstName = guest.first_name || '';
        const familyName = guest.family_name || '';

        return `${firstName} ${familyName}`.trim().replace('\\s+', ' ').toLowerCase();
      })
      .join(' '),
  }));

const getExtraGuestInfo = (guest) => {
  const address = {
    address1: guest.address_1,
    address2: guest.address_2,
    city: guest.city,
    state: guest.state_province,
    zip: guest.postal_code,
    countryCode: guest.country_code,
  };
  const completeAddress = isCompleteAddress(address);
  const missingAddress = isMissingAddress(address);

  const missingInfoMessages = [
    { property: 'address_1', message: 'Add missing street address' },
    { property: 'city', message: 'Add missing city' },
    { property: 'state_province', message: 'Add missing state/province' },
    { property: 'postal_code', message: 'Add missing zip/postal code' },
  ].reduce((values, obj) => {
    if (obj.property === 'state_province' && ['US', 'CA'].includes(guest.country_code)) {
      return !guest[obj.property] ? values.concat(obj.message) : values;
    }
    if (obj.property !== 'state_province') {
      return !guest[obj.property] ? values.concat(obj.message) : values;
    }
    return values;
  }, []);
  const missingInfoMessage =
    (missingAddress && 'Add missing address') || // All address fields are empty
    (missingInfoMessages.length === 1 && missingInfoMessages[0]) || // Missing 1 specific field
    (!completeAddress && 'Incomplete address') || // Missing more than 1, but not all fields
    ''; // No missing info

  return {
    isCompleteAddress: completeAddress,
    isMissingAddress: missingAddress,
    missingInfoMessage,
  };
};

const mapGlobalStats = (globalStats) => {
  return globalStats.map((stat) => {
    if (stat.key === 'invited_guests') {
      return {
        ...stat,
        label: 'Guests',
      };
    }
    return stat;
  });
};

const guestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_GUESTS: {
      return { ...initialState };
    }
    case ActionTypes.REQUEST_GUESTS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_GUESTS: {
      const byId = {};

      action.payload.guest_groups.forEach((guestGroup) => {
        const extraGuestInfo = getExtraGuestInfo(guestGroup);

        byId[guestGroup.id] = {
          ...guestGroup,
          ...extraGuestInfo,
        };
      });

      const orderedIds = action.payload.guest_groups.map((guestGroup) => guestGroup.id);
      return Object.assign(
        {},
        state,
        { byId, orderedIds },
        {
          facets: action.payload.facets,
          selectedFacetValues: action.payload.selected_facet_bucket_keys,
          globalStats: mapGlobalStats(action.payload.global_stats),
          busy: false,
          initialized: true,
        },
        { search: constructSearch(byId) }
      );
    }
    case ActionTypes.RECEIVE_GUEST: {
      // const updatedGuest = action.payload.guest;
      const { guest } = action.payload;
      const extraGuestInfo = getExtraGuestInfo(guest);

      const byId = Object.assign({}, state.byId, {
        [guest.id]: {
          ...guest,
          ...extraGuestInfo,
        },
      });
      return Object.assign({}, state, { byId }, { search: constructSearch(byId) });
    }
    case ActionTypes.GUEST_CREATED: {
      const newGuest = action.payload.guest;
      const byId = { ...state.byId };
      byId[action.payload.guest.id] = newGuest;
      const orderedIds = [...state.orderedIds];
      orderedIds.push(action.payload.guest.id);
      return Object.assign({}, state, { byId, orderedIds }, { search: constructSearch(byId) });
    }
    case ActionTypes.TOGGLE_INVITED: {
      const byId = { ...state.byId };
      const guestGroup = byId[action.payload.id];

      byId[action.payload.id] = Object.assign({}, guestGroup, {
        invited: action.payload.isInvited,
      });

      return Object.assign({}, state, { byId });
    }
    case ActionTypes.TOGGLE_SAVE_THE_DATE_SENT: {
      const byId = { ...state.byId };
      const guestGroup = byId[action.payload.id];

      byId[action.payload.id] = Object.assign({}, guestGroup, {
        save_the_date_sent: action.payload.isSent,
      });

      return Object.assign({}, state, { byId });
    }
    case ActionTypes.TOGGLE_INVITATION_SENT: {
      const byId = { ...state.byId };
      const guestGroup = byId[action.payload.id];

      byId[action.payload.id] = Object.assign({}, guestGroup, {
        invitation_sent: action.payload.isSent,
      });

      return Object.assign({}, state, { byId });
    }
    case ActionTypes.TOGGLE_FACET_VALUE: {
      const facetValues = state.selectedFacetValues[action.payload.facetKey]
        ? [...state.selectedFacetValues[action.payload.facetKey]]
        : [];
      const index = facetValues.findIndex((i) => i === action.payload.valueKey);
      if (index > -1) {
        facetValues.splice(index, 1);
      } else {
        facetValues.push(action.payload.valueKey);
      }

      const selectedFacetValues = { ...state.selectedFacetValues };
      selectedFacetValues[action.payload.facetKey] = facetValues;

      return Object.assign({}, state, { selectedFacetValues });
    }
    case ActionTypes.TOGGLE_SORT_BY_NAME_ASC: {
      return Object.assign({}, state, { sortByNameAsc: !state.sortByNameAsc });
    }
    case ActionTypes.UPDATE_SORTING: {
      const { sortByValue, ascending } = action.payload;
      return { ...state, sortByValue, ascending };
    }
    case ActionTypes.RESET_FACET_VALUES: {
      return Object.assign({}, state, { selectedFacetValues: {} });
    }
    case ActionTypes.REINITIALIZE: {
      return Object.assign({}, state, { initialized: false, busy: false });
    }
    case ActionTypes.EVENT_TAB_SELECTED: {
      const { id } = action.payload;
      return { ...state, activeEventTab: id };
    }
    case ActionTypes.UPDATE_ALT_ACTIVE: {
      return Object.assign({}, state, { altActiveGlobal: action.payload });
    }
    // case ActionTypes.MANUAL_SELECTED: {
    //   return Object.assign({}, state, { manualSelected: action.payload });
    // }
    case ActionTypes.SELECT_GUESTS: {
      return Object.assign({}, state, { selectedGuests: action.payload.id });
    }
    default:
      return state;
  }
};

export default guestsReducer;
