import * as ActionTypes from '../actions/types/CartActionTypes';
import { CartType } from '../actions/types/CartActionTypes';

type CartState = {
  busy: boolean;
  initialized: boolean;
  size: number;
  items: NonNullable<CartType['items']>;
  totals?: NonNullable<CartType['totals']>;
  freeShipping: NonNullable<CartType['freeShipping']> | null;
  registryId?: string;
  cartId?: string;
};
const initialState = {
  busy: false,
  initialized: false,
  size: 0, // sometimes we will only have this
  items: [],
  totals: {},
  freeShipping: null, // temporary
};

const cartReducer = (
  state: CartState = initialState,
  action: ActionTypes.CartActionTypes
): CartState => {
  switch (action.type) {
    case ActionTypes.REQUEST_SIZE: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_SIZE: {
      return Object.assign({}, state, {
        busy: false,
        size: action.payload,
      });
    }
    case ActionTypes.REQUEST_CART: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_CART: {
      if (!action.payload) return state;

      const items = action.payload.items || [];
      return {
        ...state,
        busy: false,
        initialized: true,
        size: items.length,
        items,
        totals: action.payload.totals,
        freeShipping: action.payload.freeShipping ?? null,
        cartId: action.payload.cartId,
        registryId: action.payload.registryId,
      };
    }
    case ActionTypes.RESET_CART: {
      return initialState;
    }
    default:
      return state;
  }
};

export default cartReducer;
