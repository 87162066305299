//  return 8-bit brightness value, SOURCE: https://www.w3.org/TR/AERT/#color-contrast
export const getBrightness = (rgbObj) =>
  rgbObj && (rgbObj.r * 299 + rgbObj.g * 587 + rgbObj.b * 114) / 1000;

// 60 * 1000 ms -> 60 seconds
export const autoSaveTimeSpan = 60 * 1000;
export const autoSaveMaxSpan = 3600 * 1000;

export const pageName = (type) => {
  switch (type) {
    case 'INVITATION_ENVELOPE': {
      return 'envelope';
    }
    case 'RSVP_ENVELOPE': {
      return 'rsvpEnvelope';
    }
    case 'RSVP': {
      return 'rsvp';
    }
    case 'THANK_YOU_INSIDE': {
      return 'detailInside';
    }
    case 'THANK_YOU_WRITE_NOTES': {
      return 'detailWrite';
    }
    case 'PLACE_ASSIGN': {
      return 'detailAssign';
    }
    default: {
      return 'detailFront';
    }
  }
};

// Utils for Drafts / Orders
export const formatCardName = (str) => {
  const arr = str.split(' - ');
  // INV-905
  // TODO: defensive check string for wrong string segment
  return arr.slice(0, -1).join(' - ');
};

export const formatCardNameWithMedium = (str) => {
  const cardTypeWithMedium = formatCardName(str);

  const cardType = cardTypeWithMedium.split(' ');

  if (cardType[0] === 'Paper') {
    cardType.shift();
    return cardType.join(' ');
  }

  return cardTypeWithMedium;
};

// Utils for set the cursor position
// https://www.endyourif.com/set-cursor-position-of-textarea-with-javascript/
const setSelectionRange = (input, selectionStart, selectionEnd) => {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
  } else if (input.createTextRange) {
    const range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', selectionEnd);
    range.moveStart('character', selectionStart);
    range.select();
  }
};

export const setCaretToPos = (input, pos) => {
  setSelectionRange(input, pos, pos);
};

export const getValidationErrorMessage = (errorType) => {
  switch (errorType) {
    case 'INVALID_CHARACTER':
      return `We don't support special characters or non-latin scripts yet`;
    case 'DEFAULT_RETURN_ADDRESS':
      return `Don't forget to add your own address!`;
    default:
      return `Looks like there's a problem with this element`;
  }
};
