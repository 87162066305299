import { CardCustomizationActionTypes } from '../actions/cardCustomizationActionTypes';

const initialState = {
  hasErrors: false,
  hasRecipientAddressing: false,
  errorsByElementUUID: [],
};

const cardCustomizationBar = (state = initialState, action) => {
  switch (action.type) {
    case CardCustomizationActionTypes.CUSTOMIZATION_CLEANUP: {
      return {
        ...state,
        hasErrors: false,
        errorsByElementUUID: [],
      };
    }
    case CardCustomizationActionTypes.SET_CUSTOMIZATION_ERROR_LIST: {
      return {
        ...state,
        errorsByElementUUID: action.payload,
      };
    }
    case CardCustomizationActionTypes.CLEAR_CUSTOMIZATION_ERROR_LIST: {
      return {
        ...state,
        errorsByElementUUID: [],
      };
    }
    default:
      return state;
  }
};

export default cardCustomizationBar;
