export const DATA_READY = 'zola/wedding/manage/website/manage/DATA_READY';
export const DATA_NOT_READY = 'zola/wedding/manage/website/manage/DATA_NOT_READY';

type SetDataReadyType = {
  type: typeof DATA_READY;
};

type SetDataNotReadyType = {
  type: typeof DATA_NOT_READY;
};

export type ManageWebsiteActionTypes = SetDataReadyType | SetDataNotReadyType;
