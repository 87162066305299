import dayjs from 'dayjs';

export const isSponsoredLink = (url = '') => {
  if (typeof url !== 'string') return false;

  const hasHttp = /(https|http):\/\/.+/g;
  const hasZolaDomain = /zola\.com|zola\.app|zola\.test-app.+/g;

  return hasHttp.test(url) && !hasZolaDomain.test(url);
};

export const getTaskGroup = (dueAtValue, nowValue) => {
  const now = dayjs(nowValue);
  const dueAt = dayjs(dueAtValue);
  const pastDue = now.isAfter(dueAt);

  if (pastDue) {
    return now.format('MMMM YYYY');
  }

  return dueAt.format('MMMM YYYY');
};

export const getDaysTillWeddingDate = (now, date) => {
  if (!now || !date) return null;

  const weddingDate = dayjs(date);
  const diff = weddingDate.diff(dayjs(now), 'day', true);

  // round up 1 day if the diff returns a fraction of a day
  return diff > 0 ? Math.floor(diff) + 1 : 0;
};
