import type { CardTemplateBackgroundView } from '@zola/svc-web-api-ts-client';

export const REQUEST_BACKGROUND_ASSETS = 'REQUEST_BACKGROUND_ASSETS';

export type RequestBackgroundAssetsAction = {
  type: typeof REQUEST_BACKGROUND_ASSETS;
  payload: { key: string; promise: Promise<CardTemplateBackgroundView[]> };
};

export const RECEIVE_BACKGROUND_ASSETS = 'RECEIVE_BACKGROUND_ASSETS';

export type ReceiveBackgroundAssetsAction = {
  type: typeof RECEIVE_BACKGROUND_ASSETS;
  payload: { key: string; assets: CardTemplateBackgroundView[] };
};

export type ExtraCustomizationActions =
  | RequestBackgroundAssetsAction
  | ReceiveBackgroundAssetsAction;
