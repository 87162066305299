/**
 * Created by zoechodosh on 2/17/17.
 */
import * as ActionTypes from '../actions/types/UserActionTypes';
import * as AccountInvitationActionTypes from '../actions/types/AccountInvitationActionTypes';

const initialState = {
  busy: false,
  userContext: {},
  partnerContext: [],
  hasIdentified: false,
  hasGuestIdentified: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AccountInvitationActionTypes.REQUEST_PARTNER_STATUS:
    case ActionTypes.REQUEST_USER: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_USER: {
      return Object.assign({}, state, { busy: false, userContext: action.payload });
    }
    case ActionTypes.IDENTIFY_USER: {
      return Object.assign({}, state, { hasIdentified: true });
    }
    case ActionTypes.IDENTIFY_GUEST_USER: {
      return Object.assign({}, state, { hasGuestIdentified: true });
    }
    case ActionTypes.RECEIVE_USER_STATUS: {
      const updatedUserContext = Object.assign({}, state.userContext, {
        status: action.payload.status,
      });
      return Object.assign({}, state, { busy: false, userContext: updatedUserContext });
    }
    case AccountInvitationActionTypes.RECEIVE_PARTNER_STATUS: {
      return Object.assign({}, state, { busy: false, partnerContext: action.payload });
    }
    default:
      return state;
  }
};

export default userReducer;
