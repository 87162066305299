const withoutCrop = (url) => (url || '').replace(/-\/crop\/[0-9]+x[0-9]+\/[0-9]+,[0-9]+\//, '');

/* eslint-disable prefer-destructuring */

export const autocropUploadcareFile = (uploadcareFile, imageElement, withinUserCrop = false) => {
  const {
    cdnUrlModifiers,
    crop: currentCrop,
    originalImageInfo: { height: fileHeight, width: fileWidth },
    originalUrl,
  } = uploadcareFile;
  const { height: targetHeight, width: targetWidth } = imageElement;
  const targetRatio = targetWidth / targetHeight;

  const inputCrop = withinUserCrop
    ? {
        left: typeof currentCrop?.left === 'number' ? currentCrop.left : 0,
        top: typeof currentCrop?.top === 'number' ? currentCrop.top : 0,
        width: typeof currentCrop?.width === 'number' ? currentCrop.width : fileWidth,
        height: typeof currentCrop?.height === 'number' ? currentCrop.height : fileHeight,
      }
    : { left: 0, top: 0, width: fileWidth, height: fileHeight };

  // If the files current crop already has the correct aspect ratio, let it be.
  if (currentCrop && currentCrop.width / currentCrop.height === targetRatio) {
    return uploadcareFile;
  }

  // Check if basing the crop size on image width would cause the crop height to overflow
  const canBaseCropOnImageWidth = inputCrop.width / targetRatio <= inputCrop.height;
  let height;
  let width;

  // Base the crop size on either the image's full width or full height
  if (canBaseCropOnImageWidth) {
    width = inputCrop.width;
    height = inputCrop.width / targetRatio;
  } else {
    height = inputCrop.height;
    width = inputCrop.height * targetRatio;
  }

  // Only integers are accepted in Uploadcare url modifiers, so round the cropped dimensions
  width = Math.round(width);
  height = Math.round(height);

  // Center the crop zone
  const left = inputCrop.left + Math.round((inputCrop.width - width) / 2);
  const top = inputCrop.top + Math.round((inputCrop.height - height) / 2);

  // Update cdnUrlModifiers by removing the (optional) old crop param and adding the new one
  const newModifiers = `-/crop/${width}x${height}/${left},${top}/${withoutCrop(cdnUrlModifiers)}`;

  return {
    ...uploadcareFile,
    crop: { width, height, left, top },
    cdnUrl: `${originalUrl}${newModifiers}`,
    cdnUrlModifiers: newModifiers,
  };
};
