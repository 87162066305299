import type { WThemeGroupDetailView, WThemeDetailView } from '@zola/svc-web-api-ts-client';

// Action Types
export const RECEIVE_WEBSITE_PDP_THEME = 'websitePlp/receiveThemeSearch';
export const RECEIVE_WEBSITE_PDP_THEME_GROUP = 'websitePlp/updateThemeFilters';

export type ReceiveWebsitePdpThemeAction = {
  type: typeof RECEIVE_WEBSITE_PDP_THEME;
  payload: {
    theme: WThemeDetailView;
  };
};

export const receiveWebsitePdpTheme = (theme: WThemeDetailView): ReceiveWebsitePdpThemeAction => ({
  type: RECEIVE_WEBSITE_PDP_THEME,
  payload: {
    theme,
  },
});

export type ReceiveWebsitePdpThemeGroupAction = {
  type: typeof RECEIVE_WEBSITE_PDP_THEME_GROUP;
  payload: {
    themeGroup: WThemeGroupDetailView;
  };
};

export const receiveWebsitePdpThemeGroup = (
  themeGroup: WThemeGroupDetailView
): ReceiveWebsitePdpThemeGroupAction => ({
  type: RECEIVE_WEBSITE_PDP_THEME_GROUP,
  payload: {
    themeGroup,
  },
});

export type WebsitePdpActions = ReceiveWebsitePdpThemeAction | ReceiveWebsitePdpThemeGroupAction;
