export const UploadcareActionTypes = {
  UPLOADCARE_TRANSFER_STARTED: 'zola/uploadcare/UPLOADCARE_TRANSFER_STARTED',
  UPLOADCARE_TRANSFER_COMPLETE: 'zola/uploadcare/UPLOADCARE_TRANSFER_COMPLETE',
  ZOLA_METADATA_FETCH_STARTED: 'zola/uploadcare/ZOLA_METADATA_FETCH_STARTED',
  ZOLA_METADATA_FETCH_COMPLETE: 'zola/uploadcare/ZOLA_METADATA_FETCH_COMPLETE',
  ZOLA_METADATA_BATCH_FETCH_STARTED: 'zola/uploadcare/ZOLA_METADATA_BATCH_FETCH_STARTED',
  ZOLA_METADATA_BATCH_FETCH_COMPLETE: 'zola/uploadcare/ZOLA_METADATA_BATCH_FETCH_COMPLETE',
  UPLOADCARE_FILE_FETCH_STARTED: 'zola/uploadcare/UPLOADCARE_FILE_FETCH_STARTED',
  UPLOADCARE_FILE_FETCH_COMPLETE: 'zola/uploadcare/UPLOADCARE_FILE_FETCH_COMPLETE',
  CLEAR_UPLOADCARE_FILES: 'zola/uploadcare/CLEAR_UPLOADCARE_FILES',
  CLEAR_ZOLA_METADATA: 'zola/uploadcare/CLEAR_ZOLA_METADATA',
};

export default UploadcareActionTypes;
