import React from 'react';
import { ThemeProvider } from '@emotion/react';

import COLORS, {
  COLORS_BRAND,
  COLORS_TEXT,
  COLOR_SEMANTIC,
  COLORS_BACKGROUND,
} from '@zola/zola-ui/src/styles/emotion/colors';

import COLORSV3, { COLORS_NEUTRALS } from '@zola/zola-ui/src/styles/emotion/colors3';

import FONTS from '@zola/zola-ui/src/styles/emotion/fonts';

import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const theme = {
  COLORS,
  COLORS_BRAND,
  COLORS_TEXT,
  COLOR_SEMANTIC,
  COLORS_BACKGROUND,
  COLORSV3,
  COLORS_NEUTRALS,
  FONTS,
  MEDIA_QUERY,
  SPACING,
};

export type ZolaTheme = typeof theme;

export const ZolaThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
