import { combineReducers } from 'redux';
import checklistReducer from './checklist';
import onboard from './onboard';
import tasks from './tasks';
import templates from './templates';

const checklist = combineReducers({
  checklist: checklistReducer,
  onboard,
  tasks,
  templates,
});

export type ChecklistReducerStateType = ReturnType<typeof checklist>;

export default checklist;
