import type { CardProjectPlaceCardDetailView } from '@zola/svc-web-api-ts-client';

export enum Status {
  INIT,
  LOADING,
  NOT_CREATED,
  DONE,
}

export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export interface GuestSortBy {
  primary: string;
  name: typeof SORT[keyof typeof SORT];
  table: typeof SORT[keyof typeof SORT];
}

export interface GuestEdit {
  uuid: string;
  isNew?: boolean;
  key: string;
  name: string;
  table: string;
  submitting: boolean;
}

export interface PlacecardGuestListState {
  status: Status;
  guestList: CardProjectPlaceCardDetailView | null;
  previewGuest: string | null;
  sortBy: GuestSortBy | null;
  edit: GuestEdit | null;
}

export const REQUEST_PLACECARD_GUEST_LIST = 'REQUEST_PLACECARD_GUEST_LIST';
export interface RequestPlacecardGuestList {
  type: typeof REQUEST_PLACECARD_GUEST_LIST;
}

export const RECEIVE_PLACECARD_GUEST_LIST = 'RECEIVE_PLACECARD_GUEST_LIST';
export interface ReceivePlacecardGuestList {
  type: typeof RECEIVE_PLACECARD_GUEST_LIST;
  payload?: CardProjectPlaceCardDetailView | null;
}

export const SET_PLACECARD_GUEST_LIST_EDIT = 'SET_PLACECARD_GUEST_LIST_EDIT';
export interface setPlacecardGuestListEdit {
  type: typeof SET_PLACECARD_GUEST_LIST_EDIT;
  payload?: GuestEdit | null;
}

export const SET_PLACECARD_GUEST_LIST_SORT_BY = 'SET_PLACECARD_GUEST_LIST_SORT_BY';
export interface SetPlacecardGuestListSortBy {
  type: typeof SET_PLACECARD_GUEST_LIST_SORT_BY;
  payload?: GuestSortBy | null;
}

export const SET_PLACECARD_GUEST_LIST_PREVIEW_GUEST = 'SET_PLACECARD_GUEST_LIST_PREVIEW_GUEST';
export interface SetPlacecardGuestListPreviewGuest {
  type: typeof SET_PLACECARD_GUEST_LIST_PREVIEW_GUEST;
  payload?: string | null;
}

export type PlacecardGuestListAction =
  | RequestPlacecardGuestList
  | ReceivePlacecardGuestList
  | setPlacecardGuestListEdit
  | SetPlacecardGuestListSortBy
  | SetPlacecardGuestListPreviewGuest;
