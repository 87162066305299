import type {
  WCmsEventView,
  WCmsFaqView,
  WCmsHomepageSectionView,
  WCmsPhotoView,
  WCmsVideoView,
  WCmsPoiView,
  WCmsWeddingRegistryView,
  WCmsTravelView,
  WCmsWeddingPartyMemberView,
  WCmsPageEntityView,
  WSuggestedFaqView,
  MealOptionView,
} from '@zola/svc-web-api-ts-client';

export const ENTITY_DELETE = 'zola/wedding/website/entity/ENTITY_DELETE';
export const ENTITY_DELETED = 'zola/wedding/website/entity/ENTITY_DELETED';
export const ENTITY_REQUESTED = 'zola/wedding/website/entity/ENTITY_REQUESTED';
export const ENTITY_ADD = 'zola/wedding/website/entity/ENTITY_ADD';
export const ENTITY_RECEIVED = 'zola/wedding/website/entity/ENTITY_RECEIVED';
export const ENTITIES_REQUESTED = 'zola/wedding/website/entity/ENTITIES_REQUESTED';
export const ENTITIES_RECEIVED = 'zola/wedding/website/entity/ENTITIES_RECEIVED';
export const ENTITY_SELECTED = 'zola/wedding/website/entity/ENTITY_SELECTED';
export const ENTITY_CREATED = 'zola/wedding/website/entity/ENTITY_CREATED';
export const ENTITY_MOVE = 'zola/wedding/website/entity/ENTITY_MOVE';
export const ENTITY_MOVED = 'zola/wedding/website/entity/ENTITY_MOVED';
export const SUGGESTIONS_REQUESTED = 'zola/wedding/website/entity/SUGGESTIONS_REQUESTED';
export const SUGGESTIONS_RECEIVED = 'zola/wedding/website/entity/SUGGESTIONS_RECEIVED';
export const REQUEST_ADDRESS = 'zola/wedding/website/entity/places/REQUEST_ADDRESS';
export const RESET_ADDRESS = 'zola/wedding/website/entity/places/RESET_ADDRESS';

type EntityAddType = {
  type: typeof ENTITY_ADD;
};

type EntityRequestedType = {
  type: typeof ENTITY_REQUESTED;
};

/**
 * Endpoints:
 *
 * /web-api/v2/cms/events/update: UpdateCmsEventRequest --> WCmsEventView
 * /web-api/v1/cms/page/faq/update: UpdateFaqRequest --> WCmsFaqView
 * /web-api/v1/cms/page/home/update: UpdateHomepageSectionRequest --> WCmsHomepageSectionView
 * /web-api/v1/cms/page/photo/update: UpdatePhotoRequest --> WCmsPhotoView
 * /web-api/v1/cms/page/poi/update: UpdatePointOfInterestRequest --> WCmsPointOfInterestView
 * /web-api/v1/cms/page/registry/update: UpdateWeddingRegistryRequest --> WCmsWeddingRegistryView
 * /web-api/v1/cms/page/travel/update: UpdateTravelRequest--> WCmsTravelView
 * /web-api/v1/cms/page/wedding-party/member/update: UpdateWeddingPartyMemberRequest -> WCmsWeddingPartyMemberView
 */
export interface GenericEntityType
  extends Omit<WCmsEventView, 'id' | 'type' | 'url'>,
    WCmsFaqView,
    WCmsHomepageSectionView,
    WCmsPhotoView,
    WCmsPoiView,
    Omit<WCmsPhotoView, 'url'>,
    Omit<WCmsWeddingRegistryView, 'name' | 'wedding_account_id' | 'id' | 'url'>,
    Omit<WCmsTravelView, 'type'>,
    WCmsWeddingPartyMemberView {
  id?: WCmsEventView['id'] | WCmsWeddingRegistryView['id'];
  type?: WCmsEventView['type'] | WCmsTravelView['type'];
  name?: WCmsEventView['name'] | WCmsWeddingRegistryView['name'];
  url?: WCmsPoiView['url'] | WCmsWeddingRegistryView['url'] | WCmsVideoView['url'];
  wedding_account_id?:
    | WCmsPoiView['wedding_account_id']
    | WCmsWeddingRegistryView['wedding_account_id']
    | WCmsVideoView['wedding_account_id'];
  created?: boolean;
}

export type EntitiesTypes =
  | WCmsHomepageSectionView[]
  | WCmsFaqView[]
  | WCmsPhotoView[]
  | WCmsVideoView[]
  | WCmsWeddingRegistryView[]
  | WCmsEventView[]
  | WCmsPoiView[]
  | WCmsTravelView[]
  | WCmsWeddingPartyMemberView[]
  | undefined;

type EntityReceivedType = {
  type: typeof ENTITY_RECEIVED;
  payload: { entity: GenericEntityType };
};

type EntityCreatedType = {
  type: typeof ENTITY_CREATED;
  payload: { entity: GenericEntityType };
};

type EntitiesRequestedType = {
  type: typeof ENTITIES_REQUESTED;
};

type EntitiesReceivedType = {
  type: typeof ENTITIES_RECEIVED;
  payload: { entities: GenericEntityType[]; mealOptions?: MealOptionView[] };
};

type EntityMoveType = {
  type: typeof ENTITY_MOVE;
  payload: { newIndex: number; oldIndex: number; entityName?: string; orderedIds: number[] };
};

type EntityMovedType = {
  type: typeof ENTITY_MOVED;
  payload: { entities: WCmsPageEntityView[] };
};

type EntitySelectedType = {
  type: typeof ENTITY_SELECTED;
  payload: { id?: number };
};

type EntityDeletedType = {
  type: typeof ENTITY_DELETED;
  payload: { id: number };
};

type SuggestionsRequestedType = {
  type: typeof SUGGESTIONS_REQUESTED;
};

type SuggestionsReceivedType = {
  type: typeof SUGGESTIONS_RECEIVED;
  payload: Array<WSuggestedFaqView>;
};

type RequestAddressType = {
  type: typeof REQUEST_ADDRESS;
};

type ResetAddressType = {
  type: typeof RESET_ADDRESS;
};

export type EntityActionTypes =
  | EntityAddType
  | EntityRequestedType
  | EntityReceivedType
  | EntityCreatedType
  | EntitiesRequestedType
  | EntitiesReceivedType
  | EntityMoveType
  | EntityMovedType
  | EntitySelectedType
  | EntityDeletedType
  | SuggestionsRequestedType
  | SuggestionsReceivedType
  | RequestAddressType
  | ResetAddressType;
