export const REQUEST_WEDDING = 'zola/public/wedding/request';
export const RECEIVE_WEDDING = 'zola/public/wedding/receive';

export const RECEIVE_GUEST_SEARCH = 'zola/public/wedding/RECEIVE_GUEST_SEARCH';
export const RESET_GUEST_SEARCH = 'zola/public/wedding/RESET_GUEST_SEARCH';
export const REQUEST_GUEST_RSVP = 'zola/public/wedding/REQUEST_GUEST_RSVP';
export const RECEIVE_GUEST_RSVP = 'zola/public/wedding/RECEIVE_GUEST_RSVP';
export const RESET_GUEST_RSVP = 'zola/public/wedding/RESET_GUEST_RSVP';

export const GET_GUEST_REQUESTS = 'zola/public/wedding/GET_GUEST_REQUESTS';
export const RECEIVE_GUEST_REQUEST_BY_UUID = 'zola/public/wedding/RECEIVE_GUEST_REQUEST_BY_UUID';
export const RSVP_REQUEST_SUBMITTED = 'zola/public/wedding/RSVP_REQUEST_SUBMITTED';

export const GUEST_SUCCESSFULLY_SUBMITTED = 'zola/public/wedding/GUEST_SUCCESSFULLY_SUBMITTED';

export const HIDE_ACCESS_FORM = 'zola/public/wedding/HIDE_ACCESS_FORM';
export const RECEIVE_GUEST_COUNT = 'zola/public/wedding/RECEIVE_GUEST_COUNT';

export const STORE_MOBILE_TOKEN = 'zola/public/wedding/STORE_MOBILE_TOKEN';
export const SHOW_RSVP_SUMMARY = 'zola/public/wedding/SHOW_RSVP_SUMMARY';

export const RECEIVE_VIRTUAL_EVENT_PAGE = 'zola/public/wedding/RECEIVE_VIRTUAL_EVENT_PAGE';

export const TOGGLE_POI_MAPS = 'zola/public/wedding/TOGGLE_POI_MAPS';
