import type { WebsitePreviewActionTypes } from '~/actions/types/website/WebsitePreviewActionTypes';
import * as ActionTypes from '~/actions/types/website/WebsitePreviewActionTypes';
import { WeddingDateDisplayFormatEnum } from '../../util/websiteDateUtil';

export type WebsitePreviewWeddingValues = {
  title?: string;
  hashtag?: string;
  city?: string;
  stateProvince?: string;
  date?: string; // 'YYYY-MM-DD'
  weddingDateDisplayFormat?: WeddingDateDisplayFormatEnum;
  weddingDateRangeStart?: string; // 'MM/DD/YYYY'
  weddingDateRangeEnd?: string; // 'MM/DD/YYYY'
  customDateMessage?: string;
};

export type WebsitePreviewReducerState = {
  title?: string;
  description?: string;
  hidden?: boolean;
  header_image_url?: string;
  // wedding values
  date?: string;
  hashtag?: string;
  city?: string;
  stateProvince?: string;
  // Used by Home only
  footer_image_url?: string;
  nav_title?: string;

  weddingValues: WebsitePreviewWeddingValues;
};

const initialState: WebsitePreviewReducerState = {
  weddingValues: {},
};

const websitePreviewReducer = (
  state = initialState,
  action: WebsitePreviewActionTypes
): WebsitePreviewReducerState => {
  switch (action.type) {
    case ActionTypes.UPDATE_PREVIEW: {
      return { ...state, ...action.payload };
    }
    default:
      break;
  }
  return state;
};

export default websitePreviewReducer;
