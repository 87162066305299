import { LeadCardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';

export const CARD_TYPES_WITH_ADD_MORE_TAB: LeadCardType[] = [
  'INVITATION',
  'MENU',
  'NAPKIN',
  'PROGRAM',
  'PLACE',
  'SAVE_THE_DATE',
  'SIGNAGE',
  'STICKER',
  'TABLE_NUMBER',
  'THANK_YOU',
  'WEDDING_SHOWER_INVITATION',
];

export const supportsAddMoreTab = (cardType: string) => {
  return CARD_TYPES_WITH_ADD_MORE_TAB.includes(cardType as LeadCardType);
};
