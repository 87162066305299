import { UploadCareTabTypes } from '@zola-helpers/client/dist/es/uploadcare/constants';

export const CARD_UPLOAD_TABS = [
  UploadCareTabTypes.FILE,
  UploadCareTabTypes.RECENT,
  UploadCareTabTypes.DROPBOX,
  UploadCareTabTypes.GOOGLE_PHOTOS,
  UploadCareTabTypes.ONE_DRIVE,
  UploadCareTabTypes.FACEBOOK,
  UploadCareTabTypes.INSTAGRAM,
];

export const STEPS = {
  CONFIRMATION: 'FLOW_COMPLETION',
  CUSTOMIZE: 'CUSTOMIZATION',
  WARNING: 'WARNING',
  NAMES: 'WEDDING_DETAILS_FORM',
  PHOTO: 'FILE_UPLOAD',
  PROCESSING_ORDER: 'PROCESSING_ORDER',
  SHIPPING: 'SHIPPING_DETAILS_FORM',
};
