import type { ReversePrintingLayoutsState } from '~/cards/reducers/reversePrintingLayouts';

export const UPDATE_LAYOUTS_STATE = 'cards/actions/reversePrintingLayouts/UPDATE_LAYOUTS';

interface UpdateLayoutsState {
  type: typeof UPDATE_LAYOUTS_STATE;
  payload: Partial<ReversePrintingLayoutsState>;
}

export type ReversePrintingLayoutsAction = UpdateLayoutsState;
