/**
 * @prettier
 */

import { CARD_TYPE_MAP } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { isPrimaryCustomizationCardType } from '@zola/zola-ui/src/paper/cards/constants/customizationTypes';
import { getTypeFromCustomization } from '@zola/zola-ui/src/paper/cards/util/customization';
import {
  CUSTOMIZATION_STEP_MAP,
  isCustomizationStepAllowingMultiples,
} from '@zola/zola-ui/src/paper/cards/constants/customizationSteps';
import { getCustomizationStepUrl } from '@zola/zola-ui/src/paper/cards/util/getCustomizationStepUrl';
import { getSingularTitleByCardType } from '@zola/zola-ui/src/paper/cards/constants/titleSingularByCardType';
import { CARD_TYPES_WITH_ADD_MORE_TAB } from '~/cards/constants/cardTypesWithAddMoreTab';
import { getStepKey } from './getStepKey';

const HIDDEN_SUBTOTAL_STEP_IDS = [
  CUSTOMIZATION_STEP_MAP.addressing,
  CUSTOMIZATION_STEP_MAP.addressingPreview,
  CUSTOMIZATION_STEP_MAP.reviewPaper,
  CUSTOMIZATION_STEP_MAP.addMorePaper,
];

const defaultSteps = {
  [CUSTOMIZATION_STEP_MAP.detailFront]: {
    name: 'Front',
    id: CUSTOMIZATION_STEP_MAP.detailFront,
    cardType: null,
    pageNumber: 0,
    showInBreadcrumbs: true,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.detailInside]: {
    name: 'Inside',
    id: CUSTOMIZATION_STEP_MAP.detailInside,
    cardType: null,
    pageNumber: 1,
    showInBreadcrumbs: true,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.detailWrite]: {
    name: 'Write Notes',
    id: CUSTOMIZATION_STEP_MAP.detailWrite,
    cardType: null,
    pageNumber: 1,
    showInBreadcrumbs: false,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.detailAssign]: {
    name: 'Assign',
    id: CUSTOMIZATION_STEP_MAP.detailAssign,
    cardType: null,
    pageNumber: 0,
    showInBreadcrumbs: false,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.detailBack]: {
    name: 'Back',
    id: CUSTOMIZATION_STEP_MAP.detailBack,
    cardType: null,
    pageNumber: 1,
    showInBreadcrumbs: true,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.envelope]: {
    name: 'Envelope',
    id: CUSTOMIZATION_STEP_MAP.envelope,
    cardType: CARD_TYPE_MAP.invitationEnvelope,
    pageNumber: 0,
    showInBreadcrumbs: true,
    breadcrumbGroup: CARD_TYPE_MAP.envelope,
  },
  [CUSTOMIZATION_STEP_MAP.recipients]: {
    name: 'Guests',
    id: CUSTOMIZATION_STEP_MAP.recipients,
    cardType: null,
    pageNumber: null,
    showInBreadcrumbs: false,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.addressing]: {
    name: 'Addressing',
    id: CUSTOMIZATION_STEP_MAP.addressing,
    cardType: null,
    pageNumber: null,
    showInBreadcrumbs: false,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.addressingPreview]: {
    name: 'Addressing Preview',
    id: CUSTOMIZATION_STEP_MAP.addressingPreview,
    cardType: null,
    pageNumber: null,
    showInBreadcrumbs: false,
    breadcrumbGroup: null,
  },
  [CUSTOMIZATION_STEP_MAP.rsvp]: {
    name: 'RSVP Card',
    id: CUSTOMIZATION_STEP_MAP.rsvp,
    cardType: CARD_TYPE_MAP.rsvp,
    pageNumber: 0,
    showInBreadcrumbs: true,
    breadcrumbGroup: CARD_TYPE_MAP.rsvp,
  },
  [CUSTOMIZATION_STEP_MAP.rsvpEnvelope]: {
    name: 'RSVP Envelope',
    id: CUSTOMIZATION_STEP_MAP.rsvpEnvelope,
    cardType: CARD_TYPE_MAP.rsvpEnvelope,
    pageNumber: 0,
    showInBreadcrumbs: null,
    breadcrumbGroup: CARD_TYPE_MAP.envelope,
  },
  [CUSTOMIZATION_STEP_MAP.reviewPaper]: {
    name: 'Review',
    id: CUSTOMIZATION_STEP_MAP.reviewPaper,
    showInBreadcrumbs: true,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.reviewPaper,
  },
  [CUSTOMIZATION_STEP_MAP.addMorePaper]: {
    name: 'Add More',
    id: CUSTOMIZATION_STEP_MAP.addMorePaper,
    cardType: CARD_TYPE_MAP.invitation,
    showInBreadcrumbs: false,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.addMorePaper,
    pageNumber: 0,
  },
  [CUSTOMIZATION_STEP_MAP.front]: {
    name: 'Front',
    id: CUSTOMIZATION_STEP_MAP.front,
    cardType: CARD_TYPE_MAP.dstd,
    pageNumber: 0,
    showInBreadcrumbs: true,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.front,
  },
  [CUSTOMIZATION_STEP_MAP.details]: {
    name: 'Details',
    id: CUSTOMIZATION_STEP_MAP.details,
    cardType: CARD_TYPE_MAP.dstd,
    pageNumber: 1,
    showInBreadcrumbs: true,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.details,
  },
  [CUSTOMIZATION_STEP_MAP.links]: {
    name: 'Links',
    id: CUSTOMIZATION_STEP_MAP.links,
    cardType: CARD_TYPE_MAP.dstd,
    pageNumber: 2,
    showInBreadcrumbs: true,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.links,
  },
  [CUSTOMIZATION_STEP_MAP.freeSamples]: {
    name: 'Free Samples',
    id: CUSTOMIZATION_STEP_MAP.freeSamples,
    cardType: CARD_TYPE_MAP.dstd,
    pageNumber: 2,
    showInBreadcrumbs: true,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.freeSamples,
  },
  [CUSTOMIZATION_STEP_MAP.share]: {
    name: 'Share',
    id: CUSTOMIZATION_STEP_MAP.share,
    cardType: CARD_TYPE_MAP.dstd,
    pageNumber: 3,
    showInBreadcrumbs: true,
    breadcrumbGroup: CUSTOMIZATION_STEP_MAP.share,
  },
};

function newStep(base, overrides = {}) {
  const combinedStep = { ...base, ...overrides };
  const { id, cardType } = combinedStep;
  const key = getStepKey(id, cardType);

  return { ...combinedStep, key };
}

// Add card steps (Front + Back/Inside/Assign)
function addCardSteps(customization, orderedSteps, entities) {
  const {
    inactive,
    pages: pageIds,
    custom_notes_enabled: isCustomNotesEnabled,
    should_print_guest_names: shouldPrintGuestNames,
  } = customization;
  const cardType = getTypeFromCustomization(customization);
  // Card customizations always have a detailFront step
  orderedSteps.push(newStep(defaultSteps.detailFront, { cardType }));

  // Check if a second page step (Inside or Back or Assign) is needed
  if (pageIds.length > 1 && !inactive) {
    const pages = pageIds.map((uuid) => entities.pages[uuid]);
    const pageTwo = pages.find((page) => page.page_number === 2);
    // * The custom notes feature is characterized by a specific tag on a single element 'TEXT_1'
    const allowCustomNotes = pageTwo.elements.find(
      (elmt) => entities.elements[elmt] && entities.elements[elmt].tag === 'TEXT_1'
    );

    if (pageTwo.folded && allowCustomNotes) {
      // * If custom notes is allowed on a folded card's second page (e.g. Thank Yous),
      // * add a two steps inside printing
      orderedSteps.push(newStep(defaultSteps.detailInside, { cardType })); // * landing step
      // * custom note generator step
      orderedSteps.push(
        newStep(defaultSteps.detailWrite, { cardType, showInBreadcrumbs: isCustomNotesEnabled })
      );
    } else if (!pageTwo.folded) {
      // All other cards with a second page allow reverse printing
      orderedSteps.push(newStep(defaultSteps.detailBack, { cardType }));
    }
  }

  if (cardType === CARD_TYPE_MAP.place && shouldPrintGuestNames) {
    // * Assign step (e.g. Place cards guest assignement step)
    orderedSteps.push(newStep(defaultSteps.detailAssign, { cardType, showInBreadcrumbs: true }));
  }
}

export function generateBreadcrumbs(orderedSteps) {
  return orderedSteps.filter((step) => step.showInBreadcrumbs);
}

export function generateStepsByKey(orderedSteps) {
  return orderedSteps.reduce((acc, step) => {
    acc[step.key] = step;
    return acc;
  }, {});
}

export function generateStepGroups(orderedSteps, leadCardType) {
  const displayedSteps = orderedSteps.filter((step) => !HIDDEN_SUBTOTAL_STEP_IDS.includes(step.id));

  return displayedSteps.reduce((groups, step) => {
    const { cardType } = step;

    // Find the corresponding group type
    const groupType =
      (cardType === CARD_TYPE_MAP.rsvpEnvelope && CARD_TYPE_MAP.rsvp) ||
      (cardType === CARD_TYPE_MAP.invitationEnvelope && leadCardType) ||
      cardType;

    let group = groups.find((g) => g.cardType === groupType);

    if (group) {
      group.steps.push(step);
    } else {
      group = {
        cardType: groupType,
        steps: [step],
      };
      groups.push(group);
    }

    return groups;
  }, []);
}

// TODO: Add JSDOC
export function getCustomizationNavData(
  projectUUID,
  entities,
  unorderedTypes,
  leadCustomization,
  leadSize,
  stepIdsToExclude = []
) {
  const isDigitalSaveTheDateAvailable = Boolean(unorderedTypes.DIGITAL_SAVE_THE_DATE);
  // * If custom notes is enabled, the extra step will be added to the lead customization flow only.
  let orderedSteps = [];
  const leadCardType = getTypeFromCustomization(leadCustomization);

  // * Add customization steps for Digital Save The Date
  if (isDigitalSaveTheDateAvailable) {
    orderedSteps.push(
      newStep(defaultSteps.front, {
        id: 'front',
        cardType: 'DIGITAL_SAVE_THE_DATE',
        pageNumber: 0,
      })
    );
    orderedSteps.push(
      newStep(defaultSteps.details, {
        id: 'details',
        cardType: 'DIGITAL_SAVE_THE_DATE',
        pageNumber: 1,
      })
    );
    orderedSteps.push(
      newStep(defaultSteps.links, {
        id: 'links',
        cardType: 'DIGITAL_SAVE_THE_DATE',
        pageNumber: 2,
      })
    );
    orderedSteps.push(
      newStep(defaultSteps.freeSamples, {
        id: 'freeSamples',
        cardType: 'DIGITAL_SAVE_THE_DATE',
        pageNumber: 3,
      })
    );
    orderedSteps.push(
      newStep(defaultSteps.share, {
        id: 'share',
        cardType: 'DIGITAL_SAVE_THE_DATE',
        pageNumber: 4,
      })
    );

    if (stepIdsToExclude.length) {
      orderedSteps = orderedSteps.filter((step) => !stepIdsToExclude.includes(step.id));
    }

    // Last minute updates to steps
    orderedSteps.forEach((step) => {
      // Determine the full url directly to avoid having to recalculate it later.
      /* eslint-disable no-param-reassign */
      step.url = getCustomizationStepUrl(projectUUID, step.id, step.cardType);
    });

    return {
      breadcrumbs: generateBreadcrumbs(orderedSteps),
      orderedSteps,
      steps: generateStepsByKey(orderedSteps),
      stepGroups: generateStepGroups(orderedSteps, leadCardType),
    };
  }

  // Always add lead card steps first (Front + Back/Inside/Assign)
  addCardSteps(leadCustomization, orderedSteps, entities);

  // If there is an RSVP customization, add the rsvp step
  if (unorderedTypes.RSVP) {
    orderedSteps.push(newStep(defaultSteps.rsvp));
  }

  // Add card steps (front + back/inside) for any other supported primary customizations
  const customizations = Object.values(entities.customizations);
  const otherCards = customizations.filter((customization) => {
    const isPrimaryCardType = isPrimaryCustomizationCardType(
      getTypeFromCustomization(customization)
    );
    return isPrimaryCardType && customization !== leadCustomization;
  });

  otherCards.forEach((customization) => addCardSteps(customization, orderedSteps, entities));

  // Check if addressing steps are needed
  if (leadSize === 'postcard') {
    // If the lead customization is a postcard, add postcard addressing steps
    const postcardAddressing = { cardType: leadCardType, pageNumber: 1 };
    orderedSteps.push(newStep(defaultSteps.recipients, postcardAddressing));
    orderedSteps.push(newStep(defaultSteps.addressing, postcardAddressing));
    orderedSteps.push(newStep(defaultSteps.addressingPreview, postcardAddressing));
  }

  // If there is an invitation envelope customization, add envelope addressing steps
  if (unorderedTypes.INVITATION_ENVELOPE) {
    const invitationEnvelopeAddressing = { cardType: 'INVITATION_ENVELOPE', pageNumber: 0 };
    orderedSteps.push(newStep(defaultSteps.recipients, invitationEnvelopeAddressing));
    orderedSteps.push(newStep(defaultSteps.addressing, invitationEnvelopeAddressing));
    orderedSteps.push(newStep(defaultSteps.addressingPreview, invitationEnvelopeAddressing));
    orderedSteps.push(newStep(defaultSteps.envelope));
  }

  // If there is an RSVP_ENVELOPE customization, add the rsvpEnvelope step
  if (unorderedTypes.RSVP && unorderedTypes.RSVP_ENVELOPE) {
    const rsvpEnabled = !entities.customizations[unorderedTypes.RSVP].inactive;
    orderedSteps.push(newStep(defaultSteps.rsvpEnvelope, { showInBreadcrumbs: rsvpEnabled }));
  }

  if (
    CARD_TYPES_WITH_ADD_MORE_TAB.includes(leadCardType) &&
    leadCustomization.medium !== 'DIGITAL'
  ) {
    orderedSteps.push(newStep(defaultSteps.addMorePaper, { cardType: leadCardType }));
  }

  orderedSteps.push(newStep(defaultSteps.reviewPaper));

  const hasMultiplePrimaryCards =
    orderedSteps.filter((step) => step.id === CUSTOMIZATION_STEP_MAP.detailFront).length > 1;
  const hasMultipleEnvelopeSteps =
    orderedSteps.filter((step) => step.id === CUSTOMIZATION_STEP_MAP.envelope).length > 1 ||
    orderedSteps.some((step) => step.id === CUSTOMIZATION_STEP_MAP.rsvpEnvelope);

  // Last minute updates to steps
  orderedSteps.forEach((step) => {
    /* eslint-disable no-param-reassign */
    const { cardType, id, name } = step;

    // If flow contains detail steps of multiple types or multiple envelope steps, prepend each of them with a card type.
    const isOneOfManyDetailSteps =
      hasMultiplePrimaryCards && isCustomizationStepAllowingMultiples(id);
    const isOneOfManyEnvelopeSteps =
      hasMultipleEnvelopeSteps && id === CUSTOMIZATION_STEP_MAP.envelope;
    step.name =
      (isOneOfManyDetailSteps && `${getSingularTitleByCardType(cardType)} ${name}`) ||
      (isOneOfManyEnvelopeSteps && `${getSingularTitleByCardType(leadCardType)} ${name}`) ||
      name;

    // Don't update breadcrumb group if the step is hidden or a group is already hardcoded
    if (step.showInBreadcrumbs && !step.breadcrumbGroup) {
      // When flow contains only 1 primary card type, each detail step gets its own group. Otherwise, group by card type.
      const shouldBeInOwnGroup =
        !hasMultiplePrimaryCards && isCustomizationStepAllowingMultiples(step.id);
      step.breadcrumbGroup = shouldBeInOwnGroup ? step.id : step.cardType;
    }

    // Determine the full url directly to avoid having to recalculate it later.
    step.url = getCustomizationStepUrl(projectUUID, step.id, step.cardType);
    /* eslint-enable no-param-reassign */
  });

  return {
    breadcrumbs: generateBreadcrumbs(orderedSteps),
    orderedSteps,
    steps: generateStepsByKey(orderedSteps),
    stepGroups: generateStepGroups(orderedSteps, leadCardType),
  };
}

export function updateCustomizationNavData(
  orderedSteps,
  isCustomNotesEnabled,
  cardType,
  freeSamplesAvailable = true
) {
  if (!orderedSteps || !isPrimaryCustomizationCardType(cardType)) {
    return {};
  }

  const updatedOrderedSteps = orderedSteps.map((step) => {
    if (step.id === CUSTOMIZATION_STEP_MAP.detailWrite && step.cardType === cardType) {
      return {
        ...step,
        showInBreadcrumbs: isCustomNotesEnabled,
        breadcrumbGroup: CUSTOMIZATION_STEP_MAP.detailWrite,
      };
    }

    if (step.id === CUSTOMIZATION_STEP_MAP.freeSamples) {
      return {
        ...step,
        showInBreadcrumbs: freeSamplesAvailable,
      };
    }
    return step;
  });
  return {
    breadcrumbs: generateBreadcrumbs(updatedOrderedSteps),
    orderedSteps: updatedOrderedSteps,
    steps: generateStepsByKey(updatedOrderedSteps),
    stepGroups: generateStepGroups(updatedOrderedSteps, cardType),
  };
}

export default { getCustomizationNavData, updateCustomizationNavData };
