import * as ActionTypes from '../../actions/types/website/ManageWebsiteActionTypes';
import type { ManageWebsiteActionTypes } from '../../actions/types/website/ManageWebsiteActionTypes';

export type ManageWebsiteReducerStateType = {
  dataReady: boolean;
};

const initialState = {
  dataReady: false,
};

const manageWebsiteReducer = (
  state = initialState,
  action: ManageWebsiteActionTypes
): ManageWebsiteReducerStateType => {
  switch (action.type) {
    case ActionTypes.DATA_READY: {
      return { ...state, dataReady: true };
    }
    case ActionTypes.DATA_NOT_READY: {
      return { ...state, dataReady: false };
    }
    default:
      return state;
  }
};

export default manageWebsiteReducer;
