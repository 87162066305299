import { combineReducers } from 'redux';
import guests from './guests';
import guestImport from './import';

const guestManager = combineReducers({
  guests,
  guestImport,
});

export type GuestListReducerStateType = ReturnType<typeof guestManager>;

export default guestManager;
