import type { WCmsBannerView } from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../../actions/types/website/BannerActionTypes';
import type { BannerActionTypes } from '../../actions/types/website/BannerActionTypes';

export type BannerReducerStateType = {
  busy: boolean;
  list: Array<WCmsBannerView>;
  selectedBannerId: number | null;
  bannerMessage: string;
};

const initialState = {
  busy: false,
  list: [],
  selectedBannerId: null,
  bannerMessage: '',
};

const bannerReducer = (state = initialState, action: BannerActionTypes): BannerReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_BANNER: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_BANNER_MESSAGE: {
      const { bannerMessage = '' } = action.payload; // TODO: check is empty string okay
      return { ...state, bannerMessage, busy: false };
    }
    default:
      return state;
  }
};

export default bannerReducer;
