import { combineReducers } from 'redux';
import pages from './pages';
import entities from './entities';
import banners from './banners';
import websitePreview from './websitePreview';
import manageWebsite from './manageWebsite';

const website = combineReducers({
  banners,
  pages,
  entities,
  websitePreview,
  manageWebsite,
});

export type WebsiteReducerStateType = ReturnType<typeof website>;

export default website;
